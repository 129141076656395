import React from "react";
import * as classes from "./NavBar.module.css";
import home from "../../images/home-nav.png";
import profile from "../../images/video-camera.png";
import search from "../../images/search-nav.png";
import tv from "../../images/tv-nav.png";
import { NavLink } from "react-router-dom";

const MobileBottomNav = () => {
    return (
        <>
            <div className={classes.BottomNavContainer}>
                <NavLink to="/" exact>
                    <div>
                        <img src={home} alt="" />
                        <p>Home</p>
                    </div>
                </NavLink>
                <NavLink to="/liveChannels" exact>
                    <div>
                        <img src={tv} alt="" />
                        <p>Live TV</p>
                    </div>
                </NavLink>
                <NavLink to="/all-shows" exact>
                    <div>
                        <img src={profile} alt="" />
                        <p>All shows</p>
                    </div>
                </NavLink>
                <NavLink to="/search" exact>
                    <div>
                        <img src={search} alt="" />
                        <p>Search</p>
                    </div>
                </NavLink>
            </div>
        </>
    );
};

export default MobileBottomNav;
