import EventInfoItem from "./EventInfoItem";
import * as classes from "./ChannelPlayer.module.css";
import { useEffect } from "react";

export default function EventInfoTab(props) {
    const { items, open, setOpen, setFrom, setTo, setCurrentEventName } = props;

    useEffect(() => {
        let scroll = document.getElementById("channelDayContainer").scrollHeight;
        let scrollParts = items && scroll / items.length;
        var currentEventIndex = null;

        const currentTime = new Date().getTime();

        items &&
            items.map((e, index) => {
                const startTime = new Date(e.info.startTime).getTime();
                if (startTime < currentTime) currentEventIndex = index - 2.5;
            });

        document.getElementById("channelDayContainer").scrollTo(0, currentEventIndex * scrollParts);
    }, [items]);

    return (
        <div className={classes.ChannelMain}>
            <div id="channelDayContainer" className={classes.ChannelDayContainer}>
                <div className={classes.ChannelDayWrapper}></div>

                <table className={classes.DayInfoContainer}>
                    {items && items.length > 0 ? (
                        items?.map((event, index) => {
                            //To get last item of array
                            console.log("event", event);
                            let lastIndex = false;
                            if (items.length - 1 === index) {
                                lastIndex = true;
                            }

                            return (
                                <tbody key={index}>
                                    <tr>
                                        <EventInfoItem
                                            event={event}
                                            name={event.description.en_US.name}
                                            description={event.description.en_US.shortDescription}
                                            startTime={event.info.startTime}
                                            duration={event.info.duration}
                                            setCurrentEventName={setCurrentEventName}
                                            // setCurrentEventDesc={setCurrentEventDesc}
                                            lastIndex={lastIndex}
                                            channelName={props.channelName}
                                        />
                                    </tr>
                                </tbody>
                            );
                        })
                    ) : (
                        <tbody>
                            <tr className={classes.noProgram}>
                                <td>
                                    <p>No programs available</p>
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>
        </div>
    );
}
