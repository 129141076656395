import React from "react";
import * as classes from "./Banner.module.css";

function Banner(props) {
    const { item } = props;

    return (
        <div className={classes.HeroCarousel}>
            <div className={classes.BannerImg}>
                <img src={item.coverImageSmall ? item?.coverImageSmall : item?.bannerImageSmall} alt="heroImage" width="100%" />
            </div>
        </div>
    );
}

export default Banner;
