import * as classes from "./PrivacyPolicy.module.css";

const PrivacyPolicy = (props) => {
  return (
    <div className={`${classes.tosContainer} font-100`}>
      <div
        className={`${classes.tosMainTitle} font-200 ${classes.tosMainTitle_bold_800}`}
      >
        Privacy Notice
      </div>
      <br />
      <hr />
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        {" "}
        The Personal Data Protection Act 2010 (“PDPA) requires MYTV Broadcasting
        Sdn Bhd (“MYTV”) to inform you of your rights in respect of your
        personal data that is being processed or that is to be collected and
        further processed by us and the purposes for the data processing. MYTV,
        together with its affiliates and related corporations are committed to
        the protection of your personal data. This Privacy Notice explains how
        the MYTV collects and processes your personal data in accordance with
        the PDPA..
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        The personal data and other information (collectively, “Personal Data”)
        which you provide or have provided for the use of MYTV Mana - Mana and,
        if relevant, for the use of, or purchase of MYTV Mana - Mana services
        and/or products, including any additional information you may
        subsequently provide, may be used and processed by us for the following
        purposes:
      </p>
      <br />
      <div className={classes.padded}>
        <ul>
          <li>
            to assess and process your request for our products and/or services;
          </li>
          <li>to establish your identity and background;</li>

          <li>
            assessing your application to register for the use of the MYTV Mana
            - Mana;
          </li>
          <li>assessing your application;</li>

          <li> to communicate with you;</li>

          <li>to provide services to you</li>

          <li>to process your payment transactions;</li>

          <li>to respond to your inquiries;</li>

          <li>to administer your participation in contests;</li>

          <li>to conduct internal activities;</li>
          <li>
            internal investigations, compliance, audit or security purposes
            including, without limitation, crime detection, prevention and
            prosecution;
          </li>
          <li>market surveys and trend analysis;</li>
          <li>
            to provide you with information on products and services of MYTV and
            its affiliates and related corporations;
          </li>
          <li>
            to provide you with information on products and services of our
            business partners;
          </li>
          <li>
            other legitimate business activities of MYV and its affiliates and
            related corporation;
          </li>
          <li>
            such other purposes as set out in the MYTV Mana - Mana’s Terms of
            Use; and/or
          </li>
          <li>
            {" "}
            if relevant, such other purposes as set out in the General Terms and
            Conditions and, if applicable, Campaign Terms and Conditions.
          </li>
        </ul>
      </div>
      <br />
      <br />
      <p className={`${classes.tosMainTitle_p} `}>(collectively "Purposes")</p>
      <br />
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Further, please be informed that if required for any of the foregoing
        Purposes, your Personal Data may be transferred to locations outside
        Malaysia or disclosed to any affiliates and related corporations of
        MYTV, licensees, business partners and/or service providers, who may be
        located within or outside Malaysia. Save for the foregoing, your
        Personal Data will not be knowingly transferred to any place outside
        Malaysia or be knowingly disclosed to any third party.
      </p>
      <br />
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        MYTV maintains your Personal Data in our business records while you
        remain a user of the MYTV Mana - Mana. We also maintain your Personal
        Data for a period of time after you are no longer a user, customer or
        user of any of our products and/or services or a user of the MYTV Mana -
        Mana if the Personal Data is necessary for the Purposes for which the
        Personal Data was collected or to fulfil legal requirements.
      </p>
      <br />
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        In order to process your Personal Data, your consent is required. In
        most instances, it is necessary for us to process your Personal Data in
        order to provide the service that you had requested for. However, you
        may limit the processing of your Personal Data in the manner described
        in the paragraph below.
      </p>
      <br />
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        In relation to direct marketing, you may request by written notice (in
        accordance with the following paragraph) that we do not process your
        Personal Data for any of the following purposes: (i) advertising or
        marketing via phone any product or service of the affiliates and related
        corporations of MYTV or our business partners, (ii) sending to you via
        post any advertising or marketing material relating to any product or
        service of the affiliates and related corporations of MYTV or our
        business partners; (iii) sending to you via email or SMS any advertising
        or marketing material relating to any product or service of the
        affiliates and related corporations of MYTV or our business partners, or
        (iv) communicating to you by whatever means any advertising or marketing
        material of the affiliates and related corporations of MYTV or our
        business partners.
      </p>
      <br />
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        You may at any time hereafter make inquiries, complaints and, upon
        payment of a prescribed fee, request in writing for access to, or
        correction of, your Personal Data or limit the processing of your
        Personal Data by submitting such request to the named personnel via
        registered post or email (which must be sent from the email address
        registered with MYTV) as set out below:
      </p>
      <br />
      <br />
      <div className={classes.padded}>
        <ul>
          <li>Group Head, Regulator</li>
          <li>
            MYTV Broadcasting Sdn Bhd, 2271, Jalan Usahawan 2, Cyber 6, 63000
            Cyberjaya, Selangor
          </li>
          <li>regulatory@mtvb.my</li>
        </ul>
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        If you are a past customer or user of any of our products and/or
        services, kindly be informed that we will continue to process your
        Personal Data in accordance with this Privacy Notice unless we hear
        otherwise from you. You may exercise your rights in respect of your
        Personal Data in the manner described above
      </p>
      <br />
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        This Privacy Notice may be amended at any time from time to time. Any
        amendment to this Privacy Notice shall be published on the MYTV Mana -
        Mana or by other means as we deem fit. Your continued usage of any of
        our products and/or services or of the MYTV Mana - Mana shall be deemed
        to be your agreement to the said amendment of this Privacy Notice.
      </p>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-300 `}>Privacy Policy</div>

      <div className={classes.padded}>
        <ol>
          <li>
            <div className={`${classes.tosTitle} font-300`}>STATEMENT</div>
          </li>
          <p className={`${classes.tosMainTitle_p} `}>
            The following statement explains our Privacy Policy (“Policy”) with
            respect to information including personally identifiable data
            (“Personal Data”) and other information that is collected by us when
            you access and/or use our Services. As you use our Services, we want
            you to be clear how we are using such Personal Data and the ways in
            which you can protect your privacy.
          </p>
          <br />
          <br />
          <p className={`${classes.tosMainTitle_p} `}>
            By voluntarily providing us with Personal Data, you are consenting
            to our use of it in accordance with this Policy. If you provide
            Personal Data, you acknowledge and agree that such Personal Data may
            be transferred from your current location to the offices and servers
            of MYTV.
          </p>
          <br />
          <br />
          <p className={`${classes.tosMainTitle_p} `}>
            All words and phrases used in this Policy shall have the same
            meaning given to them in our Terms of Use. Specifically, the word
            “Authorised Third Party” shall mean contractors, agents and/or third
            party supplier engaged by us from time to time for the purposes of
            providing certain Services
          </p>
          <br />
          <br />
          <li>
            <div className={`${classes.tosTitle} font-300`}>CHANGES</div>
          </li>
          <p className={`${classes.tosMainTitle_p} `}>
            Our Policy may change from time to time. As a result, at times it
            may be necessary for us to make changes to this Policy. We reserve
            the right to update, revise or modify this Policy at any time and
            from time to time without prior notice. Please review this policy
            periodically, and especially before you provide us any of your
            Personal Data. This Policy was last updated on the date indicated
            above. Your continued use of the Services after any changes,
            revisions or modifications to this Policy shall indicate your
            agreement with the terms of such revised Policy.
          </p>
          <br />
          <br />
          <li>
            <div className={`${classes.tosTitle} font-300`}>
              PARENTAL ADVISORY
            </div>
          </li>
          <p className={`${classes.tosMainTitle_p} `}>
            ONE OR MORE OF OUR SERVICES ARE NOT CATERED TO USER UNDER THE AGE OF
            18 AS OUR SERVICES MAY PROVIDE ACCESS TO MUSIC OR OTHER CONTENT THAT
            CONTAINS EXPLICIT CONTENT INCLUDING USE OF PROFANE LANGUAGE AND/OR
            SEXUAL REFERENCES. IF YOU ARE, PLEASE OBTAIN YOUR PARENT/GUARDIAN’S
            PERMISSION BEFORE USING AND/OR ACCESSING OUR SERVICES. PLEASE DO NOT
            PROVIDE US WITH YOUR PERSONAL DATA UNLESS YOU HAVE OBTAINED
            PERMISSION FROM YOUR PARENT/GUARDIAN. PARENTAL DISCRETION IS ADVISED
            FOR ALL USERS BELOW 18. WE ENCOURAGE PARENT/GUARDIAN TO MONITOR
            THEIR CHILDREN’S INTERNET USAGE AND TO HELP ENFORCE OUR POLICY BY
            INSTRUCTING THEIR CHILDREN NEVER TO PROVIDE PERSONAL DATA THROUGH
            THE SERVICE WITHOUT YOUR PERMISSION. IF YOU HAVE REASON TO BELIEVE
            THAT A CHILD UNDER THE AGE OF 18 HAS PROVIDED PERSONAL DATA TO US
            THROUGH OUR SERVICES, PLEASE CONTACT US AND WE WILL ENDEAVOUR TO
            DELETE THAT INFORMATION FROM OUR DATABASE. OTHERWISE ALL PERSONAL
            DATA PROVIDED BY A USER SHALL BE DEEMED TO HAVE BEEN SUBMITTED BY A
            USER ABOVE 18 OR HAS OBTAINED THE RELEVANT PERMISSION FROM THEIR
            PARENT/GUARDIAN.
          </p>
          <br />
          <br />
          <li>
            <div className={`${classes.tosTitle} font-300`}>
              THE INFORMATION WE COLLECT
            </div>
          </li>
          <p className={`${classes.tosMainTitle_p} `}>
            a. PERSONAL DATA THAT YOU PROVIDE TO US. We collect Personal Data
            (such as name, gender, postal address, birthdate, telephone number,
            facsimile number, email address, credit/debit card information,
            photos etc.) when you voluntarily provide such information to us as
            and when required.
          </p>
          <br />
          <br />
          <p className={`${classes.tosMainTitle_p} `}>
            b. INFORMATION WE GET FROM YOUR USE OF OUR SERVICES. When you
            interact with us through the Services, we may collect or receive and
            store certain personally non-identifiable information. Such
            information is collected passively using various technologies and
            cannot presently be used to specifically identify you. This
            information includes:
          </p>
          <br />
          <br />
          <ul style={{ display: "inline" }}>
            <li>
              device-specific information including but not limited to operating
              system version, unique device identifiers, mobile network
              information, memory, sound card data, IP address.
            </li>

            <li>
              {" "}
              cookies that may uniquely identify your browser. This is described
              in detailed below.
            </li>

            <li>
              details of how you use our services such as your search queries
            </li>

            <li>
              total number of visitors using our Services, the number of
              visitors to each page of our Services,
            </li>

            <li>how our users use and interact with our Services.</li>
          </ul>
          <br />
          <br />
          <p className={`${classes.tosMainTitle_p} `}>
            As an ongoing effort to better understand and serve the users of the
            Services, MYTV often conducts research on its customer demographics,
            interests and behavior based on the Personal Data and other
            information provided to us. This research may be compiled and
            analysed on an aggregate basis. We may share this non-identifiable
            and aggregate data with our affiliates, agents and business partners
            but this type of non-identifiable and aggregate does not identify
            you personally. We may also disclose aggregated user statistics in
            order to describe our services to current and prospective business
            partners and to other third parties for other lawful purposes.
          </p>
          <br />
          <br />
          <li>
            <div className={`${classes.tosTitle} font-300`}>
              USE OF YOUR PERSONAL DATA AND INFORMATION
            </div>
          </li>
          <p className={`${classes.tosMainTitle_p} `}>
            We use the Personal Data you provide in a manner that is consistent
            with this Policy. If you provide Personal Data for certain reason,
            we may use the Personal Data in connection with the reason for which
            it was provided. Your Personal Data and Information may also be used
            by us, our affiliates or Authorised Third Parties for the following
            reasons:
          </p>
          <br />
          <br />
          <ul style={{ display: "inline" }}>
            <li>
              For the purposes of technical administration of the Services;
            </li>

            <li>For research and development;</li>
            <li>Statistical analysis;</li>
            <li>Customer administration;</li>
            <li>Profiling services preferences;</li>
            <li>
              Finance and marketing operations and activities and/or as required
              in the provision of our services including but not limited to
              credit control services;
            </li>
            <li>
              In compliance with any regulatory, statutory or legal obligation
              imposed on us by any relevant authority;
            </li>
            <li>To operate and provide the Services to you;</li>
            <li>To support your questions about the use of the Services;</li>
            <li>To detect and prevent fraudulent activity;</li>
            <li>
              To provide other goods and services by us or its designated
              representatives and/or business partners to you or parties
              designated by you and matters ancillary thereto;
            </li>
            <li>To verify and process payment when you use the Services;</li>
            <li>
              To verify and record your personal particulars including comparing
              it with information from other sources and using the information
              to communicate with you;
            </li>
            <li>
              To conduct market research of the users of the Services including
              the number of users, the frequency of use, profile of users and
              using such analysis for our business plans, the enhancement of our
              products and services;
            </li>
            <li>
              To send you information, promotions and updates including
              marketing and advertising materials in relation to our goods and
              services and those of third party organizations selected by Us;
            </li>
          </ul>
          <br />
          <br />
          <p className={`${classes.tosMainTitle_p} `}>
            If we intend on using any Personal Data in any manner that is not
            consistent with this Policy, you will be informed of such
            anticipated use prior to or at the time at which the Personal Data
            is collected.
          </p>
          <br />
          <br />
          <li>
            <div className={`${classes.tosTitle} font-300`}>
              DISCLOSURE OF YOUR PERSONAL INFORMATION
            </div>
          </li>
          <p className={`${classes.tosMainTitle_p} `}>
            We are not in the business of selling your information. We consider
            this information to be a vital part of our relationship with you. As
            such we will not sell or rent your Personal Data save and except as
            provided herein. There are, however, certain circumstances in which
            we may share your Personal Data with certain third parties without
            further notice to you, as set forth below.
          </p>
          <br />
          <br />
          <p className={`${classes.tosMainTitle_p} `}>
            <span
              style={{
                textDecoration: "underline",
              }}
            >
              Business Transfer.
            </span>{" "}
            As we develop our business, we might sell or buy businesses or
            assets. In the event of a corporate sale, merger, reorganization,
            dissolution or similar event, Personal Data may be part of the
            transferred assets.
          </p>
          <br />
          <br />
          <p className={`${classes.tosMainTitle_p} `}>
            <span
              style={{
                textDecoration: "underline",
              }}
            >
              Legal Requirements.
            </span>{" "}
            To comply with any order of court or directive from authorities
            investigating any alleged offence, misdeed and/or abuse or to
            enforce any of the terms in the Terms of Use, Privacy Notices or,
            where such action is necessary to protect and defend the rights or
            property of MYTV.
          </p>
          <br />
          <br />
          <p className={`${classes.tosMainTitle_p} `}>
            <span
              style={{
                textDecoration: "underline",
              }}
            >
              {" "}
              Authorised Third Party.{" "}
            </span>{" "}
            Where services from such Authorised Third Party is required, we will
            share your Personal Data on a strictly confidential basis.
            Authorised Third Party will not be allowed to use your personal
            information disclosed to them except for the limited purpose of
            providing the particular service.
          </p>
          <br />
          <br />
          <li>
            <div className={`${classes.tosTitle} font-00`}>COOKIES</div>
          </li>
          <div className={`${classes.tosTitle} font-300`}>
            What is a Cookie?
          </div>
          <p className={`${classes.tosMainTitle_p} `}>
            When you use our Services, your Device will automatically be issued
            with a cookie. Cookies are text files that are placed in your
            Device's hard drive to identify your Device to our server.
            Generally, cookies themselves only record those areas of the site
            that have been visited by the Device in question, and the duration.
            Many websites and/or Software Application do this whenever a user
            visits their site in order to track traffic flow.
          </p>
          <br />
          <br />
          <p className={`${classes.tosMainTitle_p} `}>
            Depending upon the type of Device you are using, you may set your
            Device to accept all cookies, to notify you when a cookie is issued,
            or not to receive cookies at any time. The last of these, of course,
            means that certain personalised Services cannot then be provided to
            you.
          </p>
          <br />
          <br />
          <div className={`${classes.tosTitle} font-200`}>Cookies:</div>
          <p className={`${classes.tosMainTitle_p} `}>
            We use "cookies" to store and track information about you and your
            use of our Services.
          </p>
          <br />
          <br />
          <p className={`${classes.tosMainTitle_p} `}>
            Our cookie and information retrieved from our cookie is used in line
            with the uses set out in in this Policy and more specifically:
          </p>
          <br />
          <br />
          <ul style={{ display: "inline" }}>
            <li>
              to help save and retrieve passwords used on our Services. This
              way, you will not have to re-enter information upon every new
              visit to our Services.
            </li>
            <li>
              to track information such as the frequency and duration of your
              use of our Services, your click-stream as you go through our
              Services, your preferred sites, and help us determine whether you
              came to our Services from a particular Internet link or banner
              advertisement;
            </li>
            <li>
              to analyse the profile of visitors and users to helps us in
              providing you with better Services and to enhance our Services;
            </li>
            <li>
              to personalize the content, banners and promotions that you will
              see on our Services.
            </li>
            <br />
            <br />
            <p className={`${classes.tosMainTitle_p} `}>
              You are always free to decline cookies if your Device permits,
              although in that case you may not be able to use certain features
              of our Services and you may be required to re-enter your password
              more frequently during a session.
            </p>
            <br />
            <br />
          </ul>
          <li>
            <div className={`${classes.tosTitle} font-300`}>
              Third Party Cookies:
            </div>
          </li>
          <p className={`${classes.tosMainTitle_p} `}>
            We may use other third-party advertising companies to serve ads when
            you access and/or use of Services. In the course of serving
            advertisements, our third-party advertisers may place or recognize a
            unique cookie in your Device. These companies may use information
            retrieved from their cookies to provide advertisements on our
            Services and other services about goods and services that may be of
            interest to you.
          </p>
          <br />
          <br />
          <p className={`${classes.tosMainTitle_p} `}>
            WE HAVE NO ACCESS TO OR CONTROL OVER THIRD-PARTY COOKIES AND THEY
            ARE NOT SUBJECT TO OUR POLICY. PLEASE REVIEW THE PRIVACY POLICY OF
            THESE THIRD-PARTY WEBSITES RESPECTIVELY FOR DETAILS. If you want to
            prevent one of these third-party companies from sending and reading
            cookies on your Device, you will need to visit each of their
            websites individually to request that you be removed from that
            system.
          </p>
          <br />
          <br />
          <li>
            <div className={`${classes.tosTitle} font-300`}>
              OPTING-OUT, CHANGE, UPDATE OF AND ACCESS TO YOUR INFORMATION
            </div>
          </li>
          <p className={`${classes.tosMainTitle_p} `}>
            You may be able to access and/or use our Services without providing
            us any Personal Data. If you choose not to provide any Personal
            Data, you may not be able to access and/or use certain Services.
            However, when you choose to provide us with such Personal Data, we
            may also use your Personal Data and Information to contact you in
            the future to tell you about our services we believe will be of
            interest to you. If we do so, each communication we sent you will
            contain instructions permitting you to “opt-out” of receiving future
            marketing communications. In addition, if at any time you wish not
            to receive any future communications or you wish to have your name
            deleted from our mailing lists, please contact us or you may opt-out
            by replying UNSUBSCRIBE in the subject line in the email sent or by
            emailing us.
          </p>
          <br />
          <br />
          <p className={`${classes.tosMainTitle_p} `}>
            You can write to us at any time to obtain details of the Personal
            Data we hold about you. It would be helpful if you also provide
            brief details of what information you want a copy of. We may charge
            a reasonable sum permitted by existing laws to cover our
            administration costs.
          </p>
          <br />
          <br />
          <p className={`${classes.tosMainTitle_p} `}>
            We reserve the right to refuse to provide our Users with a copy of
            their Personal Data, but will give reasons for our refusal.
          </p>
          <br />
          <br />
          <li>
            <div className={`${classes.tosTitle} font-300`}>SECURITY</div>
          </li>{" "}
          <p className={`${classes.tosMainTitle_p} `}>
            The internet is not a secure medium, and communications over the
            internet may be subject to third-party interception. MYTV cannot
            accept responsibility for any unauthorised access or loss of
            personal information, save in accordance with the Terms of Use. We
            have implemented security policies, rules and technical measures to
            protect your Personal Data from unauthorised access, improper use or
            disclosure, unauthorised modification, unlawful destruction or
            accidental loss. All our employees and data processors, who have
            access to and are associated with the processing of Personal Data,
            are obliged to respect the confidentiality of your information.
          </p>
          <br />
          <br />
          <p className={`${classes.tosMainTitle_p} `}>
            While we cannot guarantee that loss, misuse or alteration of data
            will not occur; we make reasonable efforts to prevent such
            unfortunate occurrences.
          </p>
          <br />
          <br />
          <p className={`${classes.tosMainTitle_p} `}>
            Personal Data that we collect from you will be stored and backed up
            securely in an environmentally-controlled location. Only authorized
            people with user ID and password from our company have access to the
            information.
          </p>
          <br />
          <br />
          <p className={`${classes.tosMainTitle_p} `}>
            You must remember to log off after accessing and/or using our
            Services and/or close the browser window after use so that no
            unauthorized party can have access. You must not, at any time,
            divulge your Personal Data to any third party.
          </p>
          <br />
          <br />
          <li>
            <div className={`${classes.tosTitle} font-300`}>
              TRANSFERRING YOUR INFORMATION OUTSIDE OF MALAYSIA
            </div>
          </li>
          <p className={`${classes.tosMainTitle_p} `}>
            The Internet is not a secure medium, and communications over the
            Internet may be subject to third-party interception. MYTV cannot
            accept responsibility for any unauthorised access or loss of
            personal information, save in accordance with the Terms of Use.
          </p>
          <br />
          <br />
          <p className={`${classes.tosMainTitle_p} `}>
            It may be necessary to transfer your personal information to other
            group companies or services providers located in countries outside
            Malaysia. This may happen where the Authorised Third Parties are
            based outside Malaysia or where you use our Services and/or Services
            from countries outside Malaysia. By continuing usage of our Service
            and access to our Services, you hereby consented to such transfer.
          </p>
          <br />
          <br />
          <li>
            <div className={`${classes.tosTitle} font-300`}>
              CONTACTING THE WEBSITE
            </div>
          </li>
          <p className={`${classes.tosMainTitle_p} `}>
            If you have any questions about this Policy or your dealings with
            the Services, please write to: regulatory@mtvb.my
          </p>
          <br />
          <br />
        </ol>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
