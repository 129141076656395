import { useQuery } from "react-query";
import axios from "axios";
import Carousel from "react-multi-carousel";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import * as settings from "../../../configs/config_settings.json";

import { BASE_URL } from "../../../scripts/dataHandlers";
import { createToken, manageCarouselResponsiveness } from "../../../scripts/utils";
import RenderItem from "../RenderItems/RenderItem";
import CategoryTitle from "../CategoryTitles/CategoryTitle";
import CategoryTitle2 from "../CategoryTitles/CategoryTitle2/CategoryTitle2";
import useWindowDimensions from "../../WindowDimension";
import { useMyContext } from "../../../contexts/StateHolder";

const components = {
    CategoryTitle,
    CategoryTitle2,
};

const SingleCateoryAssets = ({ category, ...props }) => {
    const windowDimension = useWindowDimensions();

    const {
        id,

        routes,
        slickSettings,
        categoryTitleComponent,
        itemImageComponent,
        itemTitleComponent,
        maintainImageAspectRatio,
        imageType,
        cutText,
    } = props.settings;

    const { organizationId, key } = settings.organization;
    const { language } = settings.language;

    const { setChosenItem } = useMyContext();

    const fetchCategoryAssets = () => {
        const token = createToken(organizationId, category.id, key);

        return axios.get(`${BASE_URL}/api/publishing/getAssets?version=06&series=false&limit=8`, {
            params: {
                organizationId,
                languageId: language,
                groupItemId: category.id,
                token,
            },
        });
    };

    // need to pass unique key to react use query

    const { data: assets, isLoading } = useQuery(`${id}getAssets${category.id}`, fetchCategoryAssets, {
        // by default the cache time is 5 minutes
        cacheTime: 300000,
        staleTime: Infinity,
    });

    const clickHandler = (item, i) => {
        setChosenItem(item);
    };

    const skeletonItem = [1, 2, 3, 4, 5, 6, 7, 8];
    const TitleComponent = components[categoryTitleComponent];
    let categoryPushRoute = `/${routes.categories}/${category.id}`;
    let className1 = "";

    const renderAsset = () => {
        if (assets?.data?.assets && slickSettings) {
            className1 = manageCarouselResponsiveness(slickSettings, windowDimension, assets?.data?.assets, className1);
        }
        return (
            <>
                <div className={`${className1} categoriesContainer`} key={`${id} ${category?.id}`}>
                    <TitleComponent
                        id={category.id}
                        title={category.title}
                        routes={routes}
                        item={category}
                        showSerieViewAll={true}
                        pushRoute={categoryPushRoute}
                        extraClassname={props.extraClassname}
                    />
                    <Carousel {...slickSettings}>
                        {assets?.data?.assets?.map((item, i) => {
                            console.log("item", item);
                            let pushRoute = "";

                            let localCatId = category.id * 1;
                            if (localCatId === 215458108) {
                                // if the category id is same as trendingMyTv we show some other cat from that assets
                                localCatId = item?.folders?.find((el) => el.id !== localCatId)?.id;
                            }
                            localCatId = localCatId ? localCatId : 215458108;

                            if (item.isSerie === true || item.series?.length > 0) {
                                pushRoute = `/${routes.serieRoute}/${organizationId}/${item.id}`;
                            } else {
                                pushRoute = `/${routes.videoRoute}/${organizationId}/${item.id}/${localCatId}`;
                            }

                            return (
                                <div key={`${id} ${i}`} onClick={() => clickHandler(item, i)}>
                                    <RenderItem
                                        key={`${id} ${i}`}
                                        item={item}
                                        routes={routes}
                                        itemImageComponent={itemImageComponent}
                                        itemTitleComponent={itemTitleComponent}
                                        pushRoute={pushRoute}
                                        imageType={imageType}
                                        showCategoryName={false}
                                        showPlayIcon={true}
                                        // whether to maintain aspect ratio 16/9
                                        maintainImageAspectRatio={maintainImageAspectRatio}
                                        showDuration={props.showDuration}
                                        showReleaseYear={props.showReleaseYear}
                                        showPublishedDate={props.showPublishedDate}
                                        cutText={cutText}
                                        hideDescription={props.hideDescription}
                                        showTitle={true}
                                        folderPage={true}
                                    />
                                </div>
                            );
                        })}
                    </Carousel>
                </div>
            </>
        );
    };

    return !isLoading ? (
        renderAsset()
    ) : (
        <>
            <div className={`${className1} categoriesContainer`} key={`${id} ${category?.id}`}>
                <TitleComponent
                    id={category.id}
                    title={category.title}
                    routes={routes}
                    item={category}
                    showSerieViewAll={false}
                    extraClassname={props.extraClassname}
                />
                <Carousel {...slickSettings}>
                    {skeletonItem.map((el2) => (
                        <p key={el2}>
                            <Skeleton
                                key={el2}
                                width={"95%"}
                                height={"250px"}
                                style={{
                                    borderRadius: "16px",
                                }}
                            />
                        </p>
                    ))}
                </Carousel>
            </div>
        </>
    );
};

export default SingleCateoryAssets;
