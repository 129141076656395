import { useMyContext } from "../../contexts/StateHolder";
import { useEffect, useState } from "react";
import settings from "../../configs/config_settings.json";
import * as classes from "./TVGuide.module.css";
import TVGuideEventItem from "./TVGuideEventItem";
import { getAllEvents } from "../../scripts/dataHandlers";

export default function TVGuideEventList(props) {
    const { eventAllCount, setEventAllCount } = useMyContext();
    const organizationId = settings.organization.organizationId;
    const lcId = props.channel?.serviceId;
    const hourArr = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
    ];
    // let maxCountArr = [];

    const [events, setEvents] = useState();

    //value for current event
    const [open, setOpen] = useState(false);
    const [currentEvent, setCurrentEvent] = useState();
    const [eventStartTime, setEventStartTime] = useState();
    const [eventEndTime, setEventEndTime] = useState();
    const [percent, setPercent] = useState(100);

    // const [maxCountArr, setMaxCountArr] = useState([])

    console.log("from,to", props.from, props.to);

    useEffect(() => {
        if (currentEvent === undefined) {
            return;
        }

        // let start_time = currentEvent.info.startTime.slice(11, 16);
        // setEventStartTime(start_time)

        let st = new Date(currentEvent.info.startTime);
        st = st.getTime();
        let et = st + currentEvent.info.duration * 1000;
        // let end_time = new Date(et).toISOString().slice(11, 16);
        // setEventEndTime(end_time)

        let ct = new Date().getTime(); //current time

        let p = ((ct - st) / (currentEvent?.info.duration * 1000)) * 100;
        setPercent(p);

        // Local time
        let stDate = new Date(st);
        let etDate = new Date(et);
        let stHours = "" + stDate.getHours();
        let stMins = "" + stDate.getMinutes();
        let etHours = "" + etDate.getHours();
        let etMins = "" + etDate.getMinutes();

        while (stHours.length < 2) {
            stHours = "0" + stHours;
        }
        while (stMins.length < 2) {
            stMins = "0" + stMins;
        }
        while (etHours.length < 2) {
            etHours = "0" + etHours;
        }
        while (etMins.length < 2) {
            etMins = "0" + etMins;
        }

        let localStartTime = stHours + ":" + stMins;
        let localEndTime = etHours + ":" + etMins;

        setEventStartTime(localStartTime);
        setEventEndTime(localEndTime);
    }, [currentEvent]);

    //get all event lists
    useEffect(() => {
        const fetchAllEventsData = async () => {
            const res = await getAllEvents(organizationId, lcId, props.from, props.to);
            setEvents(res?.data.events);
        };

        fetchAllEventsData();
    }, [organizationId, lcId, props.from, props.to]);

    useEffect(() => {
        setEventAllCount([]);
    }, [setEventAllCount]);

    useEffect(() => {
        if (events === undefined) {
            return;
        }
        let newArr = [];
        for (let i = 0; i < 24; i++) {
            let cnt = events.filter((event) => event.info.startTime.slice(11, 13) === hourArr[i]).length;
            newArr.push(cnt);
        }

        if (eventAllCount.length === 0) {
            setEventAllCount(newArr);
        } else {
            let compareArr = [];
            for (let i = 0; i < 24; i++) {
                compareArr.push(Math.max(newArr[i], eventAllCount[i]));
            }

            setEventAllCount(compareArr);
        }
    }, [events, setEventAllCount]);

    const clickHandle = () => {
        window.location.pathname = `/liveChannels/${organizationId}/${lcId}`;
    };

    const handleCurrentDescription = () => {
        setOpen(!open);
    };

    return (
        <div className={classes.TVGuideEventList}>
            <ul>
                <li>
                    <div className={classes.TVGuideChannelImageContainer} style={{ backgroundColor: open ? "#414146" : "#2f2f32" }}>
                        <div className={classes.TVGuideChannelImageWrapper} onClick={clickHandle}>
                            <div className={classes.TVGuideChannelImage}>
                                <img src={props.channel.imageSmall} alt="" width="70" height="70" />
                            </div>
                        </div>
                    </div>
                </li>
                {events?.map((event, index) => (
                    <li key={index}>
                        <TVGuideEventItem
                            channelName={props.channel.title}
                            event={event}
                            setCurrentEvent={setCurrentEvent}
                            currentEventId={currentEvent?.eventId}
                            from={props.from}
                            to={props.to}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
}
