import { NavLink } from "react-router-dom";
import * as classes from "./NavBarButton.module.css";
import { useMyContext } from "../../contexts/StateHolder";
import { FiSearch } from "react-icons/fi";
// import { FaBars } from 'react-icons/fa'

const NavBarButton = (props) => {
  const { closeHamMenu } = useMyContext();

  return (
    <li className="menu-item">
      {" "}
      <NavLink
        key={props.name}
        style={props?.styles?.navBarBTN}
        to={{
          // not allowing to click on same link when they are on same page->livechannel is causing a bug when clicked livechannel from same page
          pathname: props.disableLinkFromOwnpage
            ? window.location.pathname.includes(`/${props.route}`)
              ? undefined
              : `/${props.route}`
            : `/${props.route}`,
          state: {
            title: "homepage",
          },
        }}
        exact={props.exact === false ? false : true}
        activeClassName={classes.activeSelected}
        onClick={closeHamMenu}
        className={classes.navBarSearch}
      >
        {props.icon === "FaSearch" ? (
          <FiSearch className={classes.FaIcon} />
        ) : null}
        {props.name && <div>{props.name}</div>}
      </NavLink>
    </li>
  );
};

export default NavBarButton;
