import React, { useEffect, useState } from "react";
import { validatePasswordResetToken } from "../../scripts/dataHandlers";
import ResetPasswordTokenInvalid from "./ResetPasswordTokenInvalid";
import ResetPasswordTokenValid from "./ResetPasswordTokenValid";
import Spinner from "../Spinner/Spinner";

export default function ResetPasswordChange({ tokenFromEmail }) {
  // we first need to check whether the token is valid or not

  const [tokenFromEmailvalid, setTokenFromEmailvalid] = useState(false);
  const [tokenChecked, setTokenChecked] = useState(false);

  useEffect(() => {
    const runFunction = async () => {
      const res = await validatePasswordResetToken(tokenFromEmail);

      if (res?.data?.status === "ok") {
        // means token is valid and they can change the pasword
        setTokenFromEmailvalid(true);
      } else {
        // cant change need to ask for reset password again
        setTokenFromEmailvalid(false);
      }
      setTokenChecked(true);

      console.log("res", res);
    };

    tokenFromEmail && runFunction();
  }, [tokenFromEmail]);

  return !tokenChecked ? (
    <Spinner />
  ) : tokenFromEmailvalid ? (
    <ResetPasswordTokenValid tokenFromEmail={tokenFromEmail} />
  ) : (
    <ResetPasswordTokenInvalid />
  );
}
