import React, { useEffect, useRef, useState } from "react";
import * as classes from "./RadiantPlayer.module.css";
import * as setting from "../../configs/config_settings.json";

const ReactVideoPlayer = (props) => {
    const query = new URLSearchParams(window.location.search);

    const channelName = query.get("channelName").replace(" ", "");
    const duration = query.get("duration");
    const startTime = query.get("startTime");
    let UTC = parseInt(new Date(startTime).getTime().toString().substring(0, 10));

    const catchUpRmpPlayer = useRef();

    useEffect(() => {
        const src = {
            hls: `https://vod.mana2.my/${channelName}/index-${UTC}-${duration}.m3u8`,
        };
        const settings = {
            licenseKey: window.location.hostname.includes("icareus.com") ? setting.radiantMediaLicenseIcareus : setting.radiantMediaLicense,
            src: src,
            width: 900,
            height: "100vh",
            hlsJSStopDownloadWhilePaused: true,
        };
        const elementID = catchUpRmpPlayer?.current?.id;
        const rmp = new window.RadiantMP(elementID);
        rmp.init(settings);
    }, []);

    return (
        <div className={`${classes.react_Player} replyPlayer`}>
            <div id="rmp" ref={catchUpRmpPlayer}></div>
        </div>
    );
};

export default ReactVideoPlayer;
