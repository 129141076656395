import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as classes from "./TVGuide.module.css";
import img2 from "../../images/right.png";
import img1 from "../../images/left.png";
import useWindowDimensions from "../WindowDimension";

function SampleNextArrow(props) {
  const { onClick, increase, liveChannelPage, index } = props;
  return (
    <>
      <div
        onClick={() => {
          if (index === 21) return;
          else {
            increase();
            onClick();
          }
        }}
        className={classes.arrowNext}
      >
        <img src={img2} alt="" />
      </div>
      {!liveChannelPage && (
        <div
          onClick={() => {
            if (index === 21) return;
            else {
              increase();
              onClick();
            }
          }}
          className={classes.arrowNext1}
        >
          <img src={img2} alt="" />
        </div>
      )}
    </>
  );
}

function SamplePrevArrow(props) {
  const { onClick, decrease, liveChannelPage, index } = props;
  return (
    <>
      <div
        onClick={() => {
          if (index === 7) return;
          else {
            decrease();
            onClick();
          }
        }}
        className={classes.arrowPrev}
      >
        <img src={img1} alt="" />
      </div>
      {!liveChannelPage && (
        <div
          onClick={() => {
            if (index == 7) return;
            else {
              decrease();
              onClick();
            }
          }}
          className={classes.arrowPrev1}
        >
          <img src={img1} alt="" />
        </div>
      )}
    </>
  );
}

export default function TVGuideHeader({
  setFrom,
  setTo,
  toShow,
  liveChannelPage,
}) {
  let today = new Date();
  // today = new Date(today.toUTCString());
  const [dateArr, setDateArr] = useState([]);
  const sliderRef = useRef();
  const [index, setIndex] = useState(14);

  const dimensions = useWindowDimensions();

  useEffect(() => {
    let dateArray = [];
    let start_date = new Date(today.setDate(today.getDate() - 8));
    for (let i = 1; i < 16; i++) {
      let day = new Date(start_date.setDate(start_date.getDate() + 1));
      day = day.toLocaleDateString();
      dateArray.push(day);
    }
    setDateArr(dateArray);

    //         below, we create the start and end timestamps in milliseconds in the UTC timezone, and then add the local UTC offset in minutes (returned by getTimezoneOffset()) multiplied by 60 seconds and 1000 milliseconds, which converts the offset to milliseconds. This will adjust the timestamps to account for the UTC time zone difference.

    // We then create new Date objects using these adjusted timestamps and use the toISOString() method to display the date and time in the ISO format, which is a standard format that represents the date and time in UTC timezone.

    // This should give you the start and end times adjusted for the UTC time zone difference.

    const d = new Date();
    d.setUTCHours(0, 0, 0, 0);
    // since we are different timezone we have to adjust the remaining time by adding the timezoneoffset
    const start = d.getTime() + d.getTimezoneOffset() * 60 * 1000;
    d.setUTCHours(23, 59, 59, 999);
    const end = d.getTime() + d.getTimezoneOffset() * 60 * 1000;

    setFrom(start);
    setTo(end);
  }, [setTo, setFrom]);

  // set "from" and "to" when changing the date
  useEffect(() => {
    const cd = new Date();
    const year = cd.getUTCFullYear();
    const month = cd.getUTCMonth();
    const date = cd.getUTCDate();

    //         In this code, we create a new Date object qqq in the UTC timezone by using the Date.UTC() method, and then subtract the local UTC offset in minutes (returned by getTimezoneOffset()) multiplied by 60 seconds and 1000 milliseconds to get the start time in UTC timezone. We then calculate the end time by adding 86399999 milliseconds, which is the number of milliseconds in a day minus 1 millisecond.

    // This should give you the start and end times adjusted for the UTC time zone difference.

    let qqq = new Date(Date.UTC(year, month, date - (14 - index), 0, 0, 0, 0));
    let start = qqq.getTime() + qqq.getTimezoneOffset() * 60 * 1000; // start time of select day in UTC
    let end = start + 86399999; // end time of select day in UTC

    setFrom(start);
    setTo(end);
  }, [index, setFrom, setTo]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    initialSlide: 7,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: liveChannelPage ? 1 : 3,
          //   infinite: true
        },
      },
    ],
    slidesToShow: toShow,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    nextArrow: (
      <SampleNextArrow
        liveChannelPage={liveChannelPage}
        increase={() => setIndex(index + 1)}
        index={index}
      />
    ),
    prevArrow: (
      <SamplePrevArrow
        liveChannelPage={liveChannelPage}
        decrease={() => setIndex(index - 1)}
        index={index}
      />
    ),
  };

  return (
    <Slider {...settings} ref={sliderRef}>
      {dateArr.map((day, index, dateArr) => {
        return (
          <div
            className={classes.WhatDate}
            key={index}
            style={{ display: "flex" }}
          >
            {liveChannelPage && (
              <div
                className={`${classes.previousDate} font-000`}
                // style={{
                //   flex:
                //     dateArr[index - 1] === new Date().toLocaleDateString()
                //       ? 1
                //       : 0.5,
                // }}
              >
                {dateArr[index - 1] === new Date().toLocaleDateString() ? (
                  <h4
                    className="font-000"
                    style={{
                      marginLeft: dimensions?.width > 400 ? "30px" : 0,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    What's On Today
                  </h4>
                ) : (
                  <h4 className="font-000">{dateArr[index - 1]}</h4>
                )}
              </div>
            )}

            <div
              className={classes.todaysGoal}
              //   style={{
              //     flex: day !== new Date().toLocaleDateString() ? 1 : 2,
              //   }}
            >
              {day === new Date().toLocaleDateString() && (
                <h3 className={`${classes.TVGuideHeaderTitle} font-000`}>
                  What's On Today
                </h3>
              )}
              {day !== new Date().toLocaleDateString() && (
                <h3 className={`${classes.TVGuideHeaderTitle} font-000`}>
                  {dateArr[index]}
                </h3>
              )}
            </div>
            {liveChannelPage && (
              <div
                className={`${classes.dateEnd} font-000`}
                // style={{
                //   flex:
                //     dateArr[index + 1] === new Date().toLocaleDateString()
                //       ? 1
                //       : 0.5,
                // }}
              >
                {dateArr[index + 1] === new Date().toLocaleDateString() ? (
                  <h4
                    className="font-000"
                    style={{
                      marginRight: dimensions?.width > 400 ? "30px" : 0,
                    }}
                  >
                    What's On Today
                  </h4>
                ) : (
                  <h4 className="font-000">{dateArr[index + 1]}</h4>
                )}
              </div>
            )}
          </div>
        );
      })}
    </Slider>
  );
}
