import React from "react";
import { getImageByKey } from "../../scripts/getImageByKey";
import useWindowDimensions from "../WindowDimension";
import * as classes from "./Poster.module.css";

const AddPoster = () => {
  const { width } = useWindowDimensions();

  console.log(width, 888);
  return (
    <div className={classes.PosterContent}>
      {width >= 768 ? (
        <a href="https://www.mytvbroadcasting.my/">
          <img src={getImageByKey("poster_02_march")} alt="" />
        </a>
      ) : (
        <a href="https://www.mytvbroadcasting.my/">
          <img src={getImageByKey("poster_responsive_02_march")} alt="" />
        </a>
      )}
    </div>
  );
};

export default AddPoster;
