import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

import ResetPasswordChange from "../components/ResetPassword/ResetPasswordChange";
import ResetPasswordForm from "../components/ResetPassword/ResetPasswordForm";
import Spinner from "../components/Spinner/Spinner.js";

export default function RequestResetPassword() {
  const location = useLocation();

  const [queryTokenChecked, setQueryTokenChecked] = useState(false);
  const [tokenFromEmail, setTokenFromEmail] = useState(false);

  useEffect(() => {
    const tok1 = new URLSearchParams(location.search).get("token");

    if (tok1) {
      setTokenFromEmail(tok1);
    }
    setQueryTokenChecked(true);
    return () => {
      setTokenFromEmail("");
    };
  }, [location.search]);

  return (
    <div style={{ flex: 1 }}>
      {!queryTokenChecked ? (
        <Spinner />
      ) : tokenFromEmail ? (
        <ResetPasswordChange tokenFromEmail={tokenFromEmail} />
      ) : (
        <ResetPasswordForm />
      )}
    </div>
  );
}
