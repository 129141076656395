import * as classes from "./TOS.module.css";

const TOS = (props) => {
    //const { t } = useTranslation();

    return (
        <div className={`${classes.tosContainer} font-100`} style={props?.styles?.tosContaine}>
            <div className={`${classes.tosMainTitle} font-400 ${classes.tosMainTitle_bold_800}`}>Terms of Use</div>
            <hr />
            <p className={`${classes.tosMainTitle_p} `}>
                Please read these Terms of Use carefully before accessing or using the information and services available on the website as they contain
                important information regarding your legal rights, remedies and obligations. These include various limitations and exclusions and a dispute
                resolution clause that governs how disputes will be resolved. If you do not agree to these Terms of Use, you are advised not to proceed any
                further with accessing and using the website. These Terms of Use are subject to change without prior written notice at any time, at MYTV
                Broadcasting Sdn Bhd (“MYTV”)’s sole discretion, and such changes shall apply to any purchases made after such changes are posted.
            </p>
            <br />
            <ol>
                <li>
                    <span className={`${classes.tosMainTitle}  font-300 `}>Registered Users, Billing and Discontinue Use </span>

                    <br />
                    <ol>
                        <li className={`${classes.tosMainTitle}  font-100 `}>Becoming a Registered User</li>
                        <p className={`${classes.tosMainTitle_p} `}>
                            {" "}
                            You may register as a MYTV Mana - Mana registered user of the website free of charge (a “User“). To become a User, submit your email
                            address to MYTV Mana - Mana and create a password. You are responsible for maintaining the confidentiality of your account and
                            password and for restricting access to your computer.
                        </p>
                        <br />

                        <li className={`${classes.tosMainTitle}  font-100 `}>Discontinue use</li>
                        <p className={`${classes.tosMainTitle_p} `}>
                            You may discontinue use of your MYTV Mana - Mana account at any time. We do not provide refunds or credits for any payments made. To
                            discontinue use, you would just have to simply sign out from MYTV Mana - Mana account.
                        </p>
                        <br />
                    </ol>
                </li>

                <li>
                    <span className={`${classes.tosMainTitle}  font-300 `}>Passwords & Account Access</span>
                    <ol>
                        <br />

                        <li>
                            A User has access and control over the MYTV Mana - Mana account. A User’s control is exercised through use of the User's password
                            and therefore to maintain exclusive control, the User should not reveal their password to anyone, and should maintain control over
                            the MYTV Mana - Mana enabled devices that are used to access the MYTV Mana - Mana service. You are responsible for updating and
                            maintaining the truth and accuracy of the information you provide to us relating to your account.
                        </li>
                        <br />

                        <li>
                            The MYTV Mana - Mana service and any content viewed through the service are for your personal and non-commercial use only and may
                            not be shared with individuals beyond your household. By sharing the MYTV Mana - Mana service password or allowing others to access
                            your account, the User agrees to be responsible for assuring that these household subscribers comply with these Terms of Use, and
                            such User shall be responsible for the actions of the household subscribers.
                        </li>
                        <br />

                        <li>
                            You should be mindful of any communication requesting that you submit credit card or other account information. Providing your
                            information in response to these types of communications can result in identity theft. Always access your sensitive account
                            information by going directly to the MYTV Mana - Mana and not through a hyperlink in an email or any other electronic communication,
                            even if it looks official. MYTV Mana - Mana reserves the right to place any account on hold anytime with or without notification to
                            the subscriber or member in order to protect itself and its partners from what it believes to be fraudulent activity.
                        </li>
                        <br />
                    </ol>
                </li>

                <li>
                    <span className={`${classes.tosMainTitle}  font-300 `}>MYTV Mana - Mana Service</span>
                    <ol>
                        <li>
                            You must be 18 years of age, or the age of majority, to become a subscriber to the MYTV Mana - Mana service. Minors may only use the
                            service under the supervision of an adult.
                        </li>
                        <br />

                        <li>
                            During your use of MYTV Mana - Mana we grant you a limited, non-exclusive, non-transferable right to access the MYTV Mana - Mana
                            service and view MYTV Mana - Mana content. Except for the foregoing, no right, title or interest shall be transferred to you. You
                            agree not to use the service for public performances.
                        </li>
                        <br />

                        <li>
                            You may view the MYTV Mana - Mana content only in Malaysia, and use or viewing the content outside Malaysia may constitute a
                            violation of international copyright and other laws.
                        </li>
                        <br />

                        <li>
                            The MYTV Mana - Mana service, including the content library, is regularly updated. In addition, we continually test various aspects
                            of our service, including our website, user interfaces, promotional features and availability of MYTV Mana - Mana content.
                        </li>
                        <br />

                        <li>
                            You agree not to archive, reproduce, distribute, modify, display, perform, publish, license, create derivative works from, offer for
                            sale, or use (except as explicitly authorised in these Terms of Use) content and information contained on or obtained from or
                            through the MYTV Mana - Mana service. You also agree not to: circumvent, remove, alter, deactivate, degrade or thwart any of the
                            content protections in the MYTV Mana - Mana service; use any robot, spider, scraper or other automated means to access the MYTV Mana
                            - Mana service; decompile, reverse engineer or disassemble any software or other products or processes accessible through the MYTV
                            Mana - Mana service; insert any code or product or manipulate the content of the MYTV Mana - Mana service in any way; or use any
                            data mining, data gathering or extraction method. In addition, you agree not to upload, post, e-mail or otherwise send or transmit
                            any material designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications
                            equipment associated with the MYTV Mana - Mana service, including any software viruses or any other computer code, files or
                            programs. We may terminate or restrict your use of the MYTV Mana - Mana service if you violate these Terms of Use or are engaged in
                            illegal or fraudulent use of the MYTV Mana - Mana service.
                        </li>
                        <br />

                        <li>
                            The quality of the display of the MYTV Mana - Mana content may vary from device to device, and may be affected by a variety of
                            factors, such as your location, the bandwidth available through and/or speed of your Internet connection. HD availability is subject
                            to your Internet service and device capabilities. Not all content is available in all formats, such as HD. You are responsible for
                            all Internet access charges. Please check with your Internet provider for information on possible Internet data usage charges. The
                            time it takes to begin watching MYTV Mana - Mana content will vary based on a number of factors, including your location, available
                            bandwidth at the time, the content you have selected and the configuration of your MYTV Mana - Mana -ready device.
                        </li>
                        <br />

                        <li>
                            MYTV Mana - Mana software is developed by, or for, MYTV and is designed to enable viewing of MYTV Mana - Mana content through MYTV
                            Mana - Mana -ready devices. This software may vary by device and medium, and functionalities and features may also differ between
                            devices. You acknowledge that the use of the service may require third party software that is subject to third party licenses. You
                            agree that you may automatically receive updated versions of the MYTV Mana - Mana and related third-party software.
                        </li>
                        <br />
                    </ol>
                </li>
                <li>
                    <span className={`${classes.tosMainTitle} font-300`}>Governing Law</span>
                    <br />
                    These Terms of Use shall be governed by and construed in accordance with the laws of Malaysia and any litigation or dispute in connection
                    therewith shall be submitted to the exclusive jurisdiction of the court in Malaysia. These terms will not limit any consumer protection
                    rights that you may be entitled to under the mandatory laws of your local jurisdiction.
                    <br /> <br />
                </li>
                <li>
                    <span className={`${classes.tosMainTitle} font-300`}>Applications</span>
                    <br />
                    You may encounter third-party applications (including, without limitation, websites, widgets, software, or other software utilities)
                    (“Application(s)”) that interact with the MYTV Mana - Mana service. These Applications may import data related to your MYTV Mana - Mana
                    account and activity and otherwise gather data from you. These Applications are provided solely as a convenience to you, and MYTV Mana -
                    Mana is not responsible for such Applications. Such applications are owned or operated by third parties that are not related to, with, or
                    sponsored by MYTV Mana - Mana, and may not be authorized for use with our service in all countries. Use of an application is at your own
                    option and risk
                    <br />
                    <br />
                </li>
                <li>
                    <span className={`${classes.tosMainTitle} font-300`}>Use of Information Submitted</span>
                    <br />
                    MYTV Mana - Mana is free to use any comments, information, ideas, concepts, reviews, techniques or any other material contained in any
                    communication you may send to us (“Feedback“), including responses to questionnaires or through postings to the MYTV Mana - Mana service,
                    including the MYTV Mana - Mana and user interfaces, without further compensation, acknowledgement or payment to you for any purpose
                    whatsoever including, but not limited to, developing, manufacturing and marketing products and creating, modifying or improving the MYTV
                    Mana - Mana service. In addition, you agree not to enforce any “moral rights” in and to the Feedback, to the extent permitted by applicable
                    law. Please note MYTV Mana - Mana does not accept unsolicited materials or ideas for movies, and is not responsible for the similarity of
                    any of its content or programming in any media to materials or ideas transmitted to MYTV Mana - Mana. Should you send any unsolicited
                    materials or ideas, you do so with the understanding that no additional consideration of any sort will be provided to you, and you are
                    waiving any claim against MYTV Mana - Mana and its affiliates regarding the use of such materials and ideas, even if material or an idea is
                    used that is substantially similar to the material or idea you sent.
                    <br />
                    <br />
                </li>
                <li>
                    <span className={`${classes.tosMainTitle} font-300`}>Customer Support</span>
                    <br />
                    If you need assistance of any kind, click on the menu icon at the bottom of the MYTV Mana - Mana and select “FAQ“. There you will find the
                    answers to many frequently asked questions, along with the option to contact our Customer Support team.
                    <br />
                    <br />
                </li>
                <li>
                    <span className={`${classes.tosMainTitle} font-300`}>Copyright</span> <br />
                    MYTV Mana - Mana respects the intellectual property rights of others and expects its Users to do the same. MYTV Mana - Mana enforces third
                    party’s intellectual property rights and can, in appropriate circumstances, suspend or terminate the accounts of Users who are repeat
                    infringers. MYTV Mana - Mana will respond expeditiously to claims of copyright infringement committed using the MYTV Mana - Mana platform
                    and/or website.
                    <br />
                    <br />
                    The MYTV Mana - Mana service, including all content provided on the MYTV Mana - Mana service, and all MYTV Mana - Mana interfaces created
                    for MYTV Mana - Mana -ready device including text, graphics, logos, designs, photographs, button icons, images, audio/video clips, digital
                    downloads, data compilations and software and its compilation is the property of MYTV Mana - Mana or its suppliers and is protected by
                    copyright, trade secret or other intellectual property laws and treaties.
                </li>
                <br />
                <li>
                    <span className={`${classes.tosMainTitle} font-300`}>General Terms & Conditions</span>

                    <ol>
                        <li className={`${classes.tosMainTitle}  font-100 `}>Privacy</li>
                        We collect personal information about you through and in connection with your use of the website and your use of MYTV Mana - Mana
                        services. All information that we collect about you is subject to our Privacy Policy, which forms part of these Terms of Use, and which
                        may be accessed from our home page is deemed to be incorporated into these Terms of Use.
                        <br />
                        <br />
                        <li className={`${classes.tosMainTitle}  font-100 `}>Indemnity by you</li>
                        You agree to indemnify and hold MYTV, its subsidiaries and affiliates, and each of their directors, officers, agents, contractors,
                        partners and employees, harmless from and against any loss, liability, claim, demand, damages, costs and expenses, including reasonable
                        solicitors fees, arising out of or in connection with your purchase or use of any product or services, or any violation of these Terms
                        of Use or of any law or the rights of any third party.
                        <br /> <br />
                        <li className={`${classes.tosMainTitle}  font-100 `}>Disclaimer of Warranties</li>
                        MYTV makes no warranties of any kind, express or implied, with respect to any films made available on this site. Movies and telemovies
                        and/or motion pictures are provided or sold “as is” and MYTV Mana - Mana disclaims any and all representations and warranties, whether
                        express or implied, including without limitation implied warranties of title, merchant, ability, fitness for particular purpose or
                        non-infringement. MYTV cannot guarantee and does not promise any specific results from use of services. MYTV does not represent or
                        warrant that the products, services, or any part thereof, are accurate, complete, reliable, current or error-free or that products or
                        services that are downloaded from the site are free of viruses or other harmful components. Therefore, you should exercise caution in
                        the use and downloading of any such content or materials and use industry-recognized software to detect and disinfect viruses.
                        <br />
                        <br />
                        <li className={`${classes.tosMainTitle}  font-100 `}>Limitations on Liability</li>
                        Except in jurisdictions where such provisions are restricted, in no event will MYTV or its directors, employees or agents be liable to
                        you or any third person for any indirect, consequential, exemplary, incidental, special or punitive damages, including for any lost
                        profits or lost data arising from your use of any services, even if MYTV is aware or has been advised of the possibility of such
                        damages. Notwithstanding anything to the contrary contained herein, MYTV’s liability to you for any cause whatsoever, and regardless of
                        the form of the action, will at all times be limited to the amount paid by you to MYTV for the service, but in no case will MYTV’s
                        liability to you exceed RM100.00. You acknowledge that if no amount is paid by you to MYTV for the product or service, you shall be
                        limited to injunctive relief only unless otherwise permitted by law, and you shall not be entitled to damages of any kind from MYTV,
                        regardless of the cause of action. <br />
                        <br />
                    </ol>
                </li>
            </ol>
        </div>
    );
};

export default TOS;
