import React, { useEffect, useState } from "react";
import { useMyContext } from "../../../../../contexts/StateHolder";
import { getChannels } from "../../../../../scripts/dataHandlers";
// import { createToken } from "../../../../../scripts/utils";
import CategoryTitle from "../../../CategoryTitles/CategoryTitle";
import CategoryTitle2 from "../../../CategoryTitles/CategoryTitle2/CategoryTitle2";
import useWindowDimensions from "../../../../WindowDimension";
import Carousel from "react-multi-carousel";
import * as classes from "./LiveChannels.module.css";
import RenderItem from "../../../RenderItems/RenderItem";
import * as settings from "../../../../../configs/config_settings.json";

const components = {
  CategoryTitle,
  CategoryTitle2,
};

export default function LiveChannels(props) {
  console.log("first", props);
  const { organizationId, key, language, setOrganizationId } = useMyContext();
  // Destructure props.settings
  const {
    id,
    routes,
    slickSettings,
    categoryTitleComponent,
    itemImageComponent,
    itemTitleComponent,
    maintainImageAspectRatio,
    days,
    limit,
  } = props.settings;

  const placeholderItem = {
    id: "123456789087654321",
    name: "placeholderItem",
  };

  const placeHolderArray = [
    placeholderItem,
    placeholderItem,
    placeholderItem,
    placeholderItem,
    placeholderItem,
    placeholderItem,
    placeholderItem,
  ];

  const [items, setItems] = useState(null);

  const windowDimension = useWindowDimensions();

  // useEffect(() => {
  //     const fetchLiveChannelData = async () => {
  //         setOrganizationId(settings.organization.organizationId);
  //         const res = await getChannels(settings.organization.organizationId);
  //         setItems(res?.data.channels);
  //     };

  //     fetchLiveChannelData();
  // }, [language, organizationId, key, props.latestData, days, limit, setOrganizationId]);

  useEffect(() => {
    const fetchLiveChannelData = async () => {
      const res = await getChannels(settings.organization.organizationId);
      setOrganizationId(settings.organization.organizationId);
      setItems(res?.data.channels);
    };

    (items?.length === 0 || !items) && fetchLiveChannelData();
  }, [items]);

  let className1 = "";

  const renderItems = () => {
    const TitleComponent = components[categoryTitleComponent];

    if (items?.length > 0 && slickSettings) {
      let arr = Object.keys(slickSettings.responsive).map(
        (k) => slickSettings.responsive[k]
      );
      let arr1 = [...arr];

      let j = [...arr1].find(
        (el) =>
          el.breakpoint.min <= windowDimension.width &&
          el.breakpoint.max >= windowDimension.width
      );

      if (items?.length === j.items) {
        className1 = "addSeecondary";
      } else {
        className1 = "";
      }
    }

    return (
      <div className={`${className1} ${classes.LiveChannelsContainer}`}>
        <TitleComponent
          id={"123456789098765421"}
          title={props.titleName}
          routes={routes}
          item={null}
          showSerieViewAll={false}
          isSerie={true}
          showTitle={true}
        />
        {items && items.length > 0 ? (
          <div className={classes.liveSlider}>
            <Carousel {...slickSettings}>
              {items.map((item, i) => {
                return (
                  <RenderItem
                    key={`${id} ${i}`}
                    item={item}
                    pushRoute={`/${routes.liveChannels}/${settings.organization.organizationId}/${item.serviceId}`}
                    // so in the initial render we cannot have any setTimeout ->having settimeout in initial render will cause the component to render twice.But when we change the channel the component does not seem to render the changes at all . so to avoid that we pass state variable to push and we can detect here. if the push comes from that page -->it will re-render the page
                    // pushState={{ fromDashboard: true }}

                    pushState={props.pushState}
                    //   playOnClick={false}
                    itemImageComponent={itemImageComponent}
                    imageType={"thumbnail"}
                    imageChannel={true}
                    toggleVideoPlayer={true}
                    itemTitleComponent={itemTitleComponent}
                    showTitle={props.showTitle}
                    serviceId={item.serviceId}
                    showOnNowDetails={props.showOnNowDetails}
                    liveChannel="liveChannel"
                  />
                );
              })}
            </Carousel>
          </div>
        ) : (
          <Carousel {...slickSettings}>
            {placeHolderArray.map((item, i) => {
              return (
                <RenderItem
                  key={`${id} ${i}`}
                  item={item}
                  routes={routes}
                  playOnClick={false}
                  itemImageComponent={itemImageComponent}
                  showCategoryName={false}
                  showPlayIcon={false}
                  // whether to maintain aspect ratio 16/9
                  maintainImageAspectRatio={maintainImageAspectRatio}
                  isSerie={false}
                />
              );
            })}
          </Carousel>
        )}
        <span className={classes.LiveChannelsSpan}></span>
      </div>
    );
  };
  return renderItems();
}
