import { useEffect, useState } from "react";

import * as classes from "./TVGuide.module.css";
import TVGuideChannelList from "./TVGuideChannelList";
import { getChannels } from "../../scripts/dataHandlers";
import TVGuideHeader from "./TVGuideHeader";
import settings from "../../configs/config_settings.json";

const organizationId = settings.organization.organizationId;

export default function TVGuideChannelData() {
    const [channels, setChannels] = useState();
    const [from, setFrom] = useState();
    const [to, setTo] = useState();

    useEffect(() => {
        const fetchLiveChannelData = async () => {
            const res = await getChannels(organizationId);
            setChannels(res?.data.channels);
        };

        fetchLiveChannelData();
    }, []);

    return (
        <div className={classes.TVGuideContainer}>
            <div className={classes.TVGuideHeadContainer}>
                <TVGuideHeader toShow={5} setFrom={setFrom} setTo={setTo} />
            </div>
            <div className={classes.TVGuideDataContainer}>
                <TVGuideChannelList settings={settings.components.tvGuide_01} channels={channels} from={from} to={to} />
            </div>
        </div>
    );
}
