import React from "react";
import { useEffect, useState } from "react";
import {
  getLatestAssets,
  getTrendingAssets,
  getAssets,
} from "../../scripts/dataHandlers";
import { createToken } from "../../scripts/utils";
import { useMyContext } from "../../contexts/StateHolder";
import RenderItem from "../ViewAssets/RenderItems/RenderItem";
import * as classes from "./ListAllCategories.module.css";
import { useHistory, useParams } from "react-router-dom";

import CategoryTitle2 from "../ViewAssets/CategoryTitles/CategoryTitle2/CategoryTitle2";

export default function ListAllCategories({ settings }) {
  const { organizationId, key, language } = useMyContext();
  const history = useHistory().location.pathname;
  const { title, catID } = useParams();
  const { routes, itemImageComponent, itemTitleComponent, imageType } =
    settings;

  const [filterItems, setFilterItems] = useState([]);

  useEffect(() => {
    let token;
    const fetchLatestData = async () => {
      const res = await getLatestAssets(organizationId, token, language);
      setFilterItems(res?.assets);
    };

    const fetchTrendingData = async () => {
      const res = await getTrendingAssets(organizationId, token, language);
      const response =
        res &&
        res.filter((e) => {
          if (e.isSerie !== true) {
            return e;
          }
        });
      setFilterItems(response);
    };

    const fetchAssets = async () => {
      let token = createToken(organizationId, catID, key);
      const res = await getAssets(organizationId, catID, token, language);
      setFilterItems(res);
    };

    if (organizationId && language) {
      token = createToken(organizationId, "", key);

      if (token) {
        if (history === "/all-shows/Trending") {
          fetchTrendingData();
        } else if (history === "/all-shows/Popular") {
          fetchLatestData();
        } else {
          fetchAssets();
        }
      }
    }
  }, [history, key, language, organizationId]);

  console.log(filterItems);
  return (
    <div className="">
      <div className={classes.categoryMain}>
        <CategoryTitle2
          title={title}
          routes={routes}
          showSerieViewAll={false}
          showTitle={true}
        />
        {filterItems && filterItems.length > 0 && (
          <div
            className={`${classes.ListAllCategories} font-500  `}
            style={{
              padding: "0 4%",
            }}
          >
            {filterItems.map((item) => {
              let pushRoute = "";
              if (item.isSerie === true || item.series?.length > 0) {
                pushRoute = `/${routes.serieRoute}/${organizationId}/${item.id}`;
              } else {
                pushRoute = `/${routes.videoRoute}/${organizationId}/${item.id}`;
              }

              return (
                <RenderItem
                  key={`${item.id}`}
                  item={item}
                  pushRoute={pushRoute}
                  className={classes.categoryItems}
                  routes={routes}
                  itemImageComponent={itemImageComponent}
                  itemTitleComponent={itemTitleComponent}
                  imageType={imageType}
                  textStyle={{ textAlign: "center" }}
                  renderCategory={true}
                  showTitle={true}
                  showPlayIcon={true}
                  hidePlayButton={false}
                  hideDescription={true}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
