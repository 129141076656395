import { useMyContext } from "../../../contexts/StateHolder";

import { useEffect } from "react";
import { getBanner } from "../../../scripts/dataHandlers";
import { createToken } from "../../../scripts/utils";

import * as classes from "./Banner.module.css";

import React from "react";

import axios from "axios";
import HeroBanner from "./HeroBanner";
import useWindowDimensions from "../../WindowDimension";

const Banner = (props) => {
    // Destructure props.settings

    const { id, groupItemId, slickSettings } = props.settings;
    const windowDimension = useWindowDimensions();

    // Bring stateholders from context
    const { bannerItems, setBannerItems, key, language, organizationId, user } = useMyContext();

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        async function getData() {
            try {
                // Create token for promo items
                const token = createToken(organizationId, groupItemId, key);

                // Call getItems datahandler to get list of items from categories
                const response = await getBanner(organizationId, groupItemId, token, language, user, source);

                // Set allItems as newAllItems
                let newBannerItems = { ...bannerItems };

                // Add fetched itemList to newAllItems key value (component id = key)
                newBannerItems[id] = response;

                setBannerItems(newBannerItems);
            } catch (err) {
                console.log(err);
            }
        }

        if (organizationId && groupItemId && key && language && !bannerItems[id]) {
            getData();
        }

        return () => source.cancel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupItemId]);

    if (bannerItems[id]) {
        return (
            <div className="bannerContainer">
                <div className={classes.heroContainer}>
                    <HeroBanner slickSettings={slickSettings} bannerItems={bannerItems[id]} />
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default Banner;
