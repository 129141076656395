import React from "react";
import { useEffect, useState } from "react";
import { getAssets } from "../../scripts/dataHandlers";
import { createToken } from "../../scripts/utils";
import { useMyContext } from "../../contexts/StateHolder";
import RenderItem from "../ViewAssets/RenderItems/RenderItem";
import * as classes from "./MoviesList.module.css";

export default function MoviesList(props) {
    const { organizationId, key, language } = useMyContext();

    const { routes, itemImageComponent, itemTitleComponent } = props.settings;

    const [filterItems, setFilterItems] = useState(null);

    useEffect(() => {
        let token;

        const fetchData = async () => {
            const res = await getAssets(organizationId, 215332201, token, language);

            res &&
                setFilterItems(
                    res.filter((e, index) => {
                        return index < 4 && e;
                    })
                );
        };

        if (organizationId && language) {
            token = createToken(organizationId, 215332201, key);

            if (token) {
                fetchData();
            }
        }
    }, [key, language, organizationId]);

    return (
        <>
            <div className="categoriesContainermain">
                <div className={classes.categoryMain}>
                    {filterItems && filterItems.length > 0 && (
                        <div className={`${classes.ListAllCategories} font-500`}>
                            {filterItems.map((item) => {
                                let pushRoute = "";
                                pushRoute = `/playVideo/${organizationId}/${item.id}`;

                                return (
                                    <RenderItem
                                        key={`${item.id}`}
                                        item={item}
                                        pushRoute={pushRoute}
                                        className={classes.categoryItems}
                                        routes={routes}
                                        itemImageComponent={itemImageComponent}
                                        itemTitleComponent={itemTitleComponent}
                                        imageType={props.imageType}
                                        textStyle={{ textAlign: "center" }}
                                        renderCategory={true}
                                        showPlayIcon={true}
                                        hidePlayButton={false}
                                        hideDescription={true}
                                        showTitle={true}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
