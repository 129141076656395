import { useMyContext } from "../../contexts/StateHolder";
import { useEffect, useState } from "react";

import TVGuideChannelData from "./TVGuideChannelData";
import settings from "../../configs/config_settings.json";

export default function TVGuide() {
    const { key, organizationId } = useMyContext();

    const [channelServiceId, setChannelServiceId] = useState(null);
    const [companyId, setCompanyId] = useState(null);

    useEffect(() => {
        if (organizationId) {
            setChannelServiceId(settings.organization.channelServiceId);
            setCompanyId(settings.organization.companyId);
        }
    }, [organizationId]);

    return key && channelServiceId && companyId ? (
        <div className="maxContainerPrimary">
            <TVGuideChannelData />
        </div>
    ) : null;
}
