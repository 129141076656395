import React, { useEffect, useState } from "react";
import * as classes from "./ItemTitle1.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertDuration, trancuate, trancuateDesc } from "../../../../scripts/utils";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../../WindowDimension";

export default function ItemTitle1(props) {
    const { withBackground } = props;

    const [text, setText] = useState(null);
    const [textDescription, setTextDescription] = useState(null);

    const { t } = useTranslation();

    const windowDimension = useWindowDimensions();
    useEffect(() => {
        if (props.item) {
            let textVal;

            if (props.item.seriesId && props.item.series[0].name) {
                if (!props.folderPage) {
                    textVal = props.item.programName || props.item.name || props.item.title || props.item.serie?.title;
                } else {
                    console.log("props11", props, props?.item);

                    textVal = props.item?.parentSeriesTitle ? props.item?.parentSeriesTitle : props.item.series[0].name;
                }
            } else {
                textVal = props.item.programName || props.item.name || props.item.title || props.item.serie?.title;
            }

            let textDesc = props.item?.description;

            let textDescResult;

            let result;

            if (windowDimension.width >= 550) {
                result = trancuate(textVal, 30);

                // trancuate does not work lnaguage which is not english properly and also we are using innerHTML  from suite
                textDescResult = trancuateDesc(textDesc, 190);
                console.log("working");
            } else {
                result = trancuate(textVal, 20);
                textDescResult = trancuateDesc(textDesc.replace(/<\/?[^>]+(>|$)/g, "").replace(/>\s+</g, "><"), 180);
            }

            setText(result);
            setTextDescription(textDescResult);
        }
    }, [props.item, windowDimension.width]);
    // console.log(props.liveChannel)
    return (
        text && (
            <div className={`${classes.ViewedSectionSecondary__3videos__text}`} onClick={props.onClick && props.onClick}>
                <div
                    className={`${props.liveChannel} ${
                        withBackground === "withBackground" ? classes.defaultDescription : classes.ViewedSectionSecondary__3videos__text_description
                    }`}
                    style={props.style}
                >
                    <div
                        className={`${
                            withBackground === "withBackground" ? classes.ViewedSectionSecondary__3videos__text_description_heading : classes.nobackground
                        }   font-300 itemTitleHeadingColor`}
                        style={props.textStyle}
                    >
                        {props.showTitle && text}
                    </div>
                    {!props.hideDescription && (
                        <div
                            className={`${classes.ViewedSectionSecondary__3videos__text_description_heading_desc}   font-150 thin `}
                            style={props.textStyle}
                            dangerouslySetInnerHTML={{ __html: textDescription }}
                        ></div>
                    )}

                    <div className={`${classes.ViewedSectionSecondary__3videos__text_description_duration} font-100`}>
                        {props.showCategoryName && props.item.folders && (
                            <div>
                                {/* We are taking only max 3 category name */}
                                {props.item.folders
                                    .slice(0, 3)
                                    .map((el) => el.name)
                                    .join(" , ")}
                            </div>
                        )}
                        {props.showDuration && props.showCategoryName && props.item.folders && props.item.folders.length > 0 && (
                            <div className={classes.divider}>{"•"}</div>
                        )}
                        {props.showDuration && convertDuration(props.item.duration)}
                        {props.showReleaseYear && props.showDuration && props.item.releaseYear !== 0 && <div className={classes.divider}>{"•"}</div>}

                        {props.showReleaseYear && <div>{props.item.releaseYear && props.item.releaseYear !== 0 ? props.item.releaseYear : null}</div>}
                    </div>

                    {props.showStatus && (
                        <div className={`${classes.ViewedSectionSecondary__3videos__text_description_status} font-000`}>{t("itemTitle.available")}</div>
                    )}
                    {/* {props.showPublishedDate && (
            <div
              className={`${classes.ViewedSectionSecondary__3videos__text_description_status} font-000`}
            >
              {moment(props.item.date).locale('fr').format('l')}
            </div>
          )} */}

                    {props.showActions && (
                        <div className={classes.ViewedSectionSecondary__3videos__text_actions}>
                            <p>
                                {" "}
                                <FontAwesomeIcon icon="eye" /> {props.views}k {t("itemTitle.views")}
                            </p>
                            <p>
                                <FontAwesomeIcon icon="comment" /> {props.comments}
                            </p>
                        </div>
                    )}
                    {props.showOnNowDetails && props.currentEventName && (
                        <div className={classes.descriptionWrap}>
                            <p>{props.currentEventName}</p>
                            {/* <p className={classes.timeDuration}>{props.startTime} : {props.endTime}</p> */}
                        </div>
                    )}
                </div>
                {/* {lcId && props.item.serviceId === localChannelId && <div style={{width: "100%", height: '5px', background: 'orange'}}></div>} */}
            </div>
        )
    );
}
