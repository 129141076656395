import { useMyContext } from "../../contexts/StateHolder";
import { useEffect, useState } from "react";
import settings from "../../configs/config_settings.json";
import { useHistory, useLocation } from "react-router-dom";
import * as classes from "./ChannelPlayer.module.css";
import { useTranslation } from "react-i18next";
import { getAllEvents, getChannels } from "../../scripts/dataHandlers";
//components
import EventInfoTab from "./EventInfoTab";
import LiveChannels from "../ViewAssets/Sliders/Series/LiveChannels/LiveChannels";
import LiveVideoPlayer from "../VideoPlayer/LiveVideoPlayer";
import { useParams } from "react-router-dom";
import TVGuideHeader from "../TVGuide/TVGuideHeader";
import playerCover from "./playerCover.png";
import useWindowDimensions from "../WindowDimension";
import * as setting from "../../configs/config_settings.json";

export default function ChannelOne() {
  const {
    lcId,
    organizationId = setting.organization.organizationId,
    setLcId,
    setOrganizationId,
  } = useMyContext();
  let { lcId: channelID } = useParams();
  let [channels, setChannels] = useState([]);
  const [currentEventName, setCurrentEventName] = useState("MYTV");
  //
  const { t } = useTranslation();

  const [channelName, setChannelName] = useState("TVAH");

  const [isChannelInfoFetched, setIsChannelInfoFetched] = useState(false);

  // set organizationId, set lcId
  const history = useHistory();
  const location = useLocation();
  const url = history.location.pathname;
  const myArray = url.split("/");

  useEffect(() => {
    setOrganizationId(myArray[2]);
  }, [url]);

  //fetch all event list for one channel
  const d = new Date();

  // Local time
  let h = "" + d.getHours();
  let m = "" + d.getMinutes();
  let s = "" + d.getSeconds();

  while (h.length < 2) {
    h = "0" + h;
  }
  while (m.length < 2) {
    m = "0" + m;
  }
  while (s.length < 2) {
    s = "0" + s;
  }

  let current_day = d.getDay(); // day of today

  const [items, setItems] = useState();
  const [open, setOpen] = useState(current_day);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [date, setDate] = useState(null);

  useEffect(() => {
    // get the current date and time in the local timezone
    const now = new Date();

    // calculate the start time for the current day (in UTC)
    const start = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    ).getTime();

    // calculate the start time in the local timezone by adding the timezone offset
    const startLocal = start + now.getTimezoneOffset() * 60 * 1000;

    // calculate the end time for the current day (in UTC)
    const end = start + 24 * 60 * 60 * 1000 - 1;

    // calculate the end time in the local timezone by adding the timezone offset
    const endLocal = end + now.getTimezoneOffset() * 60 * 1000;

    setFrom(startLocal);
    setTo(endLocal);
  }, [setFrom, setTo]);

  useEffect(() => {
    const fetchAllEventsData = async () => {
      const res = await getAllEvents(organizationId, lcId, from, to);
      setItems(res?.data.events);
    };

    fetchAllEventsData();
  }, [open, lcId, organizationId, from, to]);

  useEffect(() => {
    const fetchLiveChannelData = async () => {
      const res = await getChannels(setting.organization.organizationId);

      setChannels(res?.data.channels);
      if (!channelID) {
        let firstLogicalChannel = res?.data.channels?.find(
          (el) => el?.logicalChannel === 1
        );

        console.log(firstLogicalChannel, 9898);

        setLcId(firstLogicalChannel?.serviceId);
        setChannelName(firstLogicalChannel?.title);
      }

      !isChannelInfoFetched && setIsChannelInfoFetched(true);
    };

    (!isChannelInfoFetched || !channelID) && fetchLiveChannelData();
    // fetchLiveChannelData();
  }, [isChannelInfoFetched, channelID]);

  useEffect(() => {
    if (channelID && channels?.length > 0) {
      setLcId(channelID);
      let res = channels.find(
        (el) => el.serviceId * 1 === channelID * 1
      )?.title;
      console.log("reschannelone", res);

      res && setChannelName(res);
    }
  }, [channelID, channels]);

  const autoPlay = true;
  console.log(location, "autoPlay");
  const windowDimnension = useWindowDimensions();

  let currentActiveProgram = [];

  items?.find((el) => {
    let st = new Date(el?.info?.startTime).getTime();
    let duration = el?.info?.duration;
    let et = st + duration * 1000;
    let currentTime = new Date().getTime();
    console.log("st,et", st, et, currentTime);

    if (st <= currentTime && currentTime <= et) {
      currentActiveProgram.push(el);
    }
  });
  console.log("currentActiveProgram", currentActiveProgram);

  return (
    isChannelInfoFetched &&
    lcId &&
    currentEventName && (
      <div className="maxContainerPrimary channelsLive">
        <div className={classes.ChannelContainer}>
          <div
            className={classes.ChannelPlayer}
            style={{
              height:
                windowDimnension.width > 1023
                  ? `${windowDimnension.width * 0.92 * 0.6 * (9 / 16)}px`
                  : `${windowDimnension.width * 0.92 * (9 / 16)}px`,
            }}
          >
            {currentEventName && (
              <LiveVideoPlayer autoplay={!channelID ? true : false} />
            )}
          </div>
          <div className={classes.ChannelDayInfo}>
            <div className={classes.ChannelItemInfoWrapper}>
              <div className={classes.ChannelItemTitle}>
                {currentActiveProgram?.length > 0
                  ? currentActiveProgram[0]?.description?.["en_US"]?.name
                  : channelName}
              </div>
              <div className={classes.TvStatus} style={{ color: "white" }}>
                <span>{channelName}</span>
                <span> - LIVE CHANNEL</span>
                <span> - NOW PLAYING</span>
              </div>
            </div>
            <div className={classes.channelDateBar}>
              <TVGuideHeader
                liveChannelPage={true}
                toShow={1}
                setFrom={setFrom}
                setTo={setTo}
                setDate={setDate}
              />
            </div>
            {organizationId && (
              <EventInfoTab
                items={items}
                open={open}
                setOpen={setOpen}
                setFrom={setFrom}
                setTo={setTo}
                setCurrentEventName={setCurrentEventName}
                channelName={channelName}
              />
            )}
          </div>
        </div>
        <div className={classes.liveChannelsWrapper}>
          <LiveChannels
            settings={settings.components.liveChannels}
            showDuration={false}
            showReleaseYear={true}
            showPublishedDate={true}
            hideDescription={true}
            latestData={true}
            titleName={t("seriesSlider.onnow")}
            showTitle={false}
            showOnNowDetails={true}
            // push State will cause component to re-render
            pushState={{ fromDashboard: true }}
          />
        </div>
      </div>
    )
  );
}
