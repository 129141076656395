import TVGuideEventList from "./TVGuideEventList";
import Carousel from "react-multi-carousel";
import useWindowDimensions from "../WindowDimension";

export default function TVGuideChannelList(props) {
    const windowDimension = useWindowDimensions();

    const { slickSettings } = props.settings;

    let className1 = "";

    if (props.channels?.length > 0 && slickSettings) {
        let arr = Object.keys(slickSettings.responsive).map((k) => slickSettings.responsive[k]);
        let arr1 = [...arr];

        let j = [...arr1].find((el) => el.breakpoint.min <= windowDimension.width && el.breakpoint.max >= windowDimension.width);

        if (props.channels?.length === j.items) {
            className1 = "addSeecondary";
        } else {
            className1 = "";
        }
    }
    console.log("props", props);

    return (
        <div className={`${className1}`}>
            {props.channels?.length > 0 && (
                <Carousel {...slickSettings}>
                    {props.channels.map((channel, index) => {
                        return <TVGuideEventList key={index} channel={channel} from={props.from} to={props.to} />;
                    })}
                </Carousel>
            )}
        </div>
    );
}
