import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { createAssetIdToken, relpaceCDNImageURL } from "../../scripts/utils";
import { useMyContext } from "../../contexts/StateHolder";
import { getAsset } from "../../scripts/dataHandlers";
import * as setting from "../../configs/config_settings.json";

export default function RadiantPlayer(props) {
  console.log("RadiantPlayer props", props);
  const rmpPlayer = useRef();

  const { chosenItem, language, key, setChosenItem, assetProperty, user } =
    useMyContext();

  const { t } = useTranslation();

  useEffect(() => {
    let vodjs, advertjs;

    if (chosenItem && props.isSerie && !chosenItem.serie && props.itemId) {
      // finding ffolder name and id of series asset basically for google analytics
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const fetchChosenItem = async () => {
        const token5 = createAssetIdToken(
          props.organizationId,
          props.itemId,
          language,
          key
        );

        const res = await getAsset(
          props.organizationId,
          props.itemId,
          token5,
          language,
          assetProperty,
          user,
          source
        );
        console.log(res, "res assets");
        setChosenItem(res);
      };
      fetchChosenItem();
    }

    if (props.secretKey && props.itemId) {
      // console.log(props.secretKey && props.itemId && chosenItem);
      const createScript = () => {
        vodjs = document.createElement("script");
        advertjs = document.createElement("script");

        vodjs.id = "vodjs";

        //url for script files -> these are just for mytv

        vodjs.src =
          "https://static.mana2.my/lib/js/v4/players/vodApiUpdated.js";
        advertjs.src = "https://static.mana2.my/lib/js/v4/players/adverts.js";

        vodjs.async = true;
        advertjs.async = true;

        document.body.appendChild(vodjs);
        document.body.appendChild(advertjs);
      };

      createScript();

      let customErrorMessage = t("videoPlayer.customErrorMessage");
      let noSupportMessage = t("videoPlayer.noSupportMessage");
      let noSupportDownload = t("videoPlayer.noSupportDownload");
      let noSupportInstallChrome = t("videoPlayer.noSupportInstallChrome");
      let noSupportInstallChromeLink = t(
        "videoPlayer.noSupportInstallChromeLink"
      );

      // replacements
      // let teamName = "seura";

      const labels = {
        error: {
          customErrorMessage,
          noSupportMessage,
          noSupportDownload,
          noSupportInstallChrome,
          noSupportInstallChromeLink,
        },
      };

      window._icareus = {};

      window._icareus.companyId = props.companyId;

      window._icareus.groupId = props.groupId;

      window._icareus.organizationId = props.organizationId;

      window._icareus.itemId = props.itemId;

      window._icareus.host =
        setting.API_Server_Number === 1
          ? "https://api.mana2.my"
          : "https://suiterc.icareus.com";
      window._icareus.playerId = "rmpPlayer";
      window._icareus.playerType = "radiant";
      window._icareus.playerSetup = "vodJSCallback";
      window._icareus.playerAutoStart = true;
      window._icareus.userId = props.userId;
      window._icareus.licenseFileUrl =
        "//cache.mana2.my/suite/radiantplayerlicenses.json";
      window._icareus.streamRootKeysUrl =
        "//cache.mana2.my/suite/streamrootkeys.json";

      // vod adverts stuff start

      window._icareus.campaignId = props.campaignIDD;
      // window._icareus.advertVOD = props.campaignIDD ? true : false;
      // window._icareus.useAdvertsLibrary = props.campaignIDD ? true : false;
      window._icareus.advertVOD = false;
      window._icareus.useAdvertsLibrary = false;

      window._icareus.applicationTypeId = 1;
      window._icareus.applicationId = 1;

      // vod adverts end here

      window._icareus.token = createAssetIdToken(
        props.organizationId,
        props.itemId,
        false,
        props.secretKey
      );

      // google analytics as instructed by digital starts here only if tracking id provided
      if (chosenItem && props.googleTrackingId) {
        window._icareus.gaLabel = ` ${
          chosenItem?.name
            ? chosenItem.name.toUpperCase()
            : chosenItem.title.toUpperCase()
        }[${props.itemId}]`;
        window._icareus.gaCategory = props.isSerie ? "Series" : "Videos";
        window._icareus.gatrackingId = props.googleTrackingId;
        window._icareus.isSerie = props.isSerie ? props.isSerie : false;
        window._icareus.gaFolderLabel = props.isSerie
          ? `${chosenItem.serie?.title}[${chosenItem.serie?.id}]`
          : chosenItem.folders && chosenItem.folders.length > 0
          ? `${chosenItem.folders[0].name}/${chosenItem.folders.id}`
          : "Demo Title For Videos";
        window._icareus.gaFolderCategory = "Folder";
      }
      // google analytics as instructed by digital ends here

      window._icareus.initializedByReactApps = true;

      let settings;

      window.vodJSCallback = function () {
        settings = {
          labels: labels,
          detectViewerLanguage: false,

          licenseKey: window.location.hostname.includes("icareus.com")
            ? setting.radiantMediaLicenseIcareus
            : setting.radiantMediaLicense,
          src: window._icareus.sources,

          autoHeightMode: true,
          autoHeightModeRatio: 1.7777777778,

          // The 4 player skins ('s1', 's2', 'outstream', 'tv') can easily be colorized using the following player settings.
          skin: props.skin ? props.skin : "s1",
          // This setting will colorize the background of the skin. Default to ''.

          skinBackgroundColor: props.skinBackgroundColor
            ? props.skinBackgroundColor
            : "rgba(33, 33, 33, 0.85)",
          skinButtonColor: "rgba(255, 255, 255, 1)",
          skinAccentColor: "rgba(130, 177, 255, 1)",

          speed: props.speed ? true : false,
          automaticFullscreenOnLandscape: true,
          ads: props.campaignIDD ? true : false,

          adTagUrl: window._icareus.videoVastURL,

          contentMetadata: {
            title: props.title ? props.title : null,
            description: props.description ? props.description : null,
            poster: [
              relpaceCDNImageURL(
                props.poster ? props.poster : window._icareus.thumbnail,
                1500
              ),
            ],
          },

          ccFiles: null,

          // https://www.radiantmediaplayer.com/docs/latest/media-preloading.html  we are changing the preload from smart to auto ->need to test what advantage it brings to the table
          preload: "auto",

          asyncElementID: rmpPlayer.current.id,
        };

        // when ready event fires we append our custom overlay div element
        // so that this element is appended on top of other player elements
        console.log("settings", settings);

        window._icareus.playerObject.init({ ...settings });
      };
      return () => {
        document.body.removeChild(vodjs);
        document.body.removeChild(advertjs);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div ref={rmpPlayer} id="rmpPlayer"></div>;
}
