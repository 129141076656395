import Collapse, { Panel } from "rc-collapse";
import React, { useState } from "react";
import "rc-collapse/assets/index.css";

import * as classes from "./FAQ.module.css";
import "./FAQ.css";

const arrowPath =
  "M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88" +
  ".5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3." +
  "6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-0.7 5." +
  "2-2L869 536.2c14.7-12.8 14.7-35.6 0-48.4z";

function expandIcon({ isActive }) {
  return (
    <i style={{ marginRight: ".5rem" }}>
      <svg
        viewBox="0 0 1024 1024"
        width="1em"
        height="1em"
        fill="currentColor"
        style={{
          verticalAlign: "-.125em",
          transition: "transform .2s",
          transform: `rotate(${isActive ? 90 : 0}deg)`,
        }}
      >
        <path d={arrowPath} p-id="5827" />
      </svg>
    </i>
  );
}

const FAQTEXT = [
  {
    header: "What is MYTV Mana Mana?",
    text: "MYTV Mana Mana is a freemium streaming service that allows viewers to watch LIVE TV shows, movies or drama series for free on an internet-connected device.",
  },
  {
    header: "Do I have to register to get started?",
    text: "You can enjoy free videos on MYTV Mana Mana without registering with us.",
  },
  {
    header: "What kind of videos can I access via MYTV Mana Mana?",
    text: "We offer various LIVE TV channels in Malaysia under MYTV OTT platform. You can also watch a wide variety of on demand shows from great local dramas, international blockbusters, and many more via this platform. MYTV Mana Mana content will be updated from time to time to serve Malaysian viewers.",
  },
  {
    header: "Does Mana Mana offer Catch-up TV shows?",
    text: "Don’t worry about missing your favorite shows. By using our catch up feature, you can now watch past 7 days selected content from our LIVE TV shows at any time.",
  },
  {
    header: "What is TV guide?",
    text: "MYTV Mana Mana offers Electronic Program guide for you to get an information about the featured shows of TV channels in MYTV Mana Mana platform",
  },
];
const FAQTEXT1 = [
  {
    header: "Do I have to sign in to watch the content?",
    text: "No sign in or registration required as all the content on MYTV Mana Mana is FREE.",
  },
  {
    header: "What are the supported devices required?",
    text: "You can watch Mana Mana through any internet-connected device. Mana Mana can be watched through an internet browser or through the Mana Mana app which is available for mobile devices, tablets, smart TVs, streaming media players, and more.",
  },
  {
    header: "How many devices can I use to access content?",
    text: "Currently, there is no limit of devices to access our content.",
  },
  {
    header: "Can I remove the advertisement?",
    text: "Currently, it is not possible to remove the advertisements on MYTV Mana Mana. The advertisements help Mana Mana to come out with more compelling content in the future.",
  },
  {
    header: "How do I search for content I want to watch on MYTV Mana Mana?",
    text: `For web version, you can click “SEARCH” button on top right corner of the main page, ENTER any keywords and CLICK the SEARCH button to get information on the searched content.

    For app version, you can click “SEARCH” icon on the shortcut at the bottom of the screen, ENTER any keywords and CLICK the SEARCH button to get information on the searched content.`,
  },
];

const FAQ = () => {
  const [activeKey, setActiveKey] = useState(["What is MYTV Mana Mana?0"]);
  const getItems = (elements) => {
    const items = [];

    elements.forEach((el, key) => {
      items.push(
        <Panel header={el.header} key={`${el.header}${key}`}>
          <p>{el.text}</p>
        </Panel>
      );
    });

    return items;
  };

  const onChange = (activeKey) => {
    setActiveKey(activeKey);
  };

  return (
    <div
      style={{
        color: "white",
      }}
      className="font-200"
    >
      <div className={classes.faqContainer}>
        <div className={classes.faqTitle}>FAQs </div>

        <Collapse
          accordion={true}
          onChange={onChange}
          activeKey={activeKey}
          expandIcon={expandIcon}
        >
          <div className={`${classes.faqsubTitle} font-400`}>
            {" "}
            Getting Started
          </div>

          {getItems(FAQTEXT)}
          <div className={`${classes.faqsubTitle} font-400`}>
            {" "}
            Watching content on Mana Mana
          </div>

          {getItems(FAQTEXT1)}
        </Collapse>
        <div>
          <div className={`${classes.faqsubTitle} font-400`}> Contact Us</div>
          Please contact careline@mytvbroadcasting.my for any inquiries on MYTV
          Mana Mana.
        </div>
      </div>
    </div>
  );
};

export default FAQ;
