import { useMyContext } from "../../contexts/StateHolder";
import LivePlayer1 from "./LivePlayer1";
import { useEffect, useState } from "react";
import settings from "../../configs/config_settings.json";
import { useCookies } from "react-cookie";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import { useMemo } from "react";

const LiveVideoPlayer = (props) => {
  const { autoplay = false } = props;

  const {
    key,
    lcId,
    organizationId = settings.organization.organizationId,
    liveChannelPrivate,
  } = useMyContext();

  const [cookies] = useCookies("");
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  console.log("history", history);
  console.log("lcId19", lcId);
  const memoizedLcId = useMemo(() => lcId, [lcId]);

  console.log(
    !loading && key && organizationId && memoizedLcId,
    loading,
    key,
    organizationId,
    memoizedLcId,
    "liveplayer12"
  );

  useEffect(() => {
    if (memoizedLcId) {
      // so in the initial render we cannot have any setTimeout ->having settimeout in initial render will cause the component to render twice.But when we change the channel the component does not seem to render the changes at all . so to avoid that we pass state variable to push and we can detect here. if the push comes from that page -->it will re-render the page
      if (history.action === "PUSH" && history.location?.state?.fromDashboard) {
        setTimeout(() => {
          setLoading(false);
          // alert(1);
        }, 10);
      } else {
        setLoading(false);
        // alert(2);
      }
    }
    return () => {
      setLoading(true);
      window._icareus?.playerObject?.destroy();
    };
  }, [memoizedLcId]);

  return !loading && key && organizationId && memoizedLcId ? (
    <div className="maxContainerPrimary">
      <LivePlayer1
        companyId={settings.organization.companyId}
        groupId={settings.organization.groupId} // Group of organization
        organizationId={settings.organization.organizationId} // Id of organization
        // organization={organizationId}
        playerAutoStart={props.playerAutoStart}
        secretKey={key} // Secret key for organization
        channelServiceId={memoizedLcId} // channelServiceId for live video
        backRoute={props.backRoute} // Route, where back button takes user. If undefined, it takes automatically to previous route
        userId={liveChannelPrivate ? cookies.userData.userId : 0}
        license={settings.organization.radiantPlayerLicense}
        autoplay={autoplay}
        loading={loading}
      />
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
      className="maxContainerPrimary"
    >
      {" "}
      <Loader type="TailSpin" color="#febd0e" height={50} width={50} />
    </div>
  );
};

export default LiveVideoPlayer;
