import Skeleton from "react-loading-skeleton";
import classes from "./SkeletonVideoPlayer.module.css";
import useWindowDimensions from "../WindowDimension";
import { getImageByKey } from "../../scripts/getImageByKey";

const SkeletonVideoPlayer = () => {
    const windowDimnension = useWindowDimensions();
    return (
        <div className={classes.container}>
            {/* <Skeleton
        width={"100%"}
        height={
          windowDimnension.width > 1023
            ? `${windowDimnension.width * 0.92 * 0.6 * (9 / 16) - 55}px`
            : `${windowDimnension.width * (9 / 16) - 100}px`
        }
      />
      <div className={classes.rmpButtonContainer}>
        <Skeleton width={"100%"} height={"50px"} />
      </div> */}

            <img src={getImageByKey("videoplayerloading")} width={"100%"} height="100%" alt="loading" />
        </div>
    );
};

export default SkeletonVideoPlayer;
