import SingleCateoryAssets from "./SingleCateoryAssets";
import { useMyContext } from "../../../contexts/StateHolder";

const ListAllRootCategories = (props) => {
    const { allCategories } = useMyContext();

    return <>{allCategories?.length > 0 && allCategories.map((el, i) => <SingleCateoryAssets category={el} {...props} key={el?.id || i.toString()} />)}</>;
};

export default ListAllRootCategories;
