import { Link, NavLink, useHistory } from "react-router-dom";
import { getImageByKey } from "../../scripts/getImageByKey";
import { useTranslation } from "react-i18next";
import * as classes from "./NavBar.module.css";
import { CgMenu, CgClose } from "react-icons/cg";
// import { useState } from 'react';
import { useMyContext } from "../../contexts/StateHolder";
import MobileBottomNav from "./MobileBottomNav";
import settings from "../../configs/config_settings.json";
import facebook from "../../images/facebook.png";
import insta from "../../images/instra.png";
import profile from "../../images/profile.png";
import { useCookies } from "react-cookie";

const NavBar = ({
  links,

  styles,
  linksMobileLogin,
  linksMobileSearch,
}) => {
  const { t } = useTranslation();
  const [cookies, removeCookie] = useCookies("");
  const history = useHistory();
  const {
    isMenu,
    setisMenu,
    closeHamMenu,
    isResponsiveclose,
    setResponsiveclose,
    user,
    setUser,
  } = useMyContext();

  console.log(user, "navbaruser");

  const logOut = () => {
    console.log(cookies);

    setTimeout(() => {
      if (cookies?.userData) {
        removeCookie("userData", 1, {
          path: "/",
          expires: new Date(Date.now()),
        });
      }
      if (cookies?.redirectUrl) {
        removeCookie("redirectUrl", 1, {
          path: "/",
          expires: new Date(Date.now()),
        });
      }
      if (cookies?.ue) {
        removeCookie("ue", 1, { path: "/", expires: new Date(Date.now()) });
      }
      if (cookies?.osec) {
        removeCookie("osec", 1, { path: "/", expires: new Date(Date.now()) });
      }
      setUser({});
      window.location.reload();
    }, 200);

    // history.push(`/`);
  };

  // for main navigation mobile menu
  const toggleClass = () => {
    console.log(`toggle`, isMenu, isResponsiveclose);
    setisMenu(isMenu === false ? true : false);
    setResponsiveclose(isResponsiveclose === false ? true : false);
  };

  let boxClass = ["main-menu menu-right menuq1"];
  if (isMenu) {
    boxClass.push("menuq2");
  } else {
    boxClass.push("");
  }

  const mobileLinks = () => {
    return (
      <div
        className={classes.dropdownContent}
        onClick={() => {
          setisMenu(!isMenu);
          setResponsiveclose(!isResponsiveclose);
        }}
      >
        {!user?.loggedIn ? (
          <Link to="/login" onClick={logOut} style={{ color: "white" }}>
            {t("login.loginButton")}
          </Link>
        ) : (
          <Link to={`/${settings.routes.profile}`}>
            {" "}
            <div className={classes.MenuItem}>
              Profile
              <img src={profile} alt="" />
            </div>
          </Link>
        )}
        <div
          style={{ borderTop: "1px solid rgba(255, 255, 255, 0.438)" }}
          className={classes.MenuItem}
        >
          <a
            href="https://www.facebook.com/mytvbroadcasting/"
            style={{ border: "none" }}
          >
            Social Media <img src={facebook} alt="" />
          </a>
        </div>
        <Link to="/tos">Term of Use</Link>
        <Link to={`/${settings.routes.privacyPolicy}`} exact>
          <div>Privacy Policy</div>
        </Link>
        <div style={{ borderTop: "1px solid rgba(255, 255, 255, 0.438)" }}>
          <a
            href="https://mytvbroadcasting.my/contact-us/"
            style={{ border: "none" }}
          >
            Contact Us
          </a>
        </div>

        <Link to={`/${settings.routes.faq}`} exact>
          <div> FAQs</div>
        </Link>

        {user?.loggedIn && (
          <Link to="/login" onClick={logOut} style={{ color: "white" }}>
            {t("login.logOutButton")}
          </Link>
        )}
      </div>
    );
  };

  return (
    <>
      <div className={classes.navColorBar}>
        <div className={classes.navColorBar1}></div>
        <div className={classes.navColorBar2}></div>
      </div>
      <div
        className={`${isResponsiveclose && classes.navBar1} ${classes.navBar}`}
        style={styles?.navBar}
      >
        {
          // site logo for big screen
          <NavLink to="/" exact activeClassName="no-class">
            <img
              className="navBarSiteLogo"
              src={getImageByKey("siteLogo")}
              title={t("navBar.backToHome")}
              alt="SiteName"
              style={styles?.navBar}
              onClick={closeHamMenu}
            ></img>
          </NavLink>
        }

        <nav className={`${classes.navBarRow} main-nav `}>
          {/* navbar for the big screen*/}

          <div className={"navBarSiteLogoMobile"}>
            <NavLink to="/" exact activeClassName="no-class">
              <img
                src={getImageByKey("siteLogo")}
                title={t("navBar.backToHome")}
                alt="SiteName"
                style={styles?.navBar}
                onClick={closeHamMenu}
                className="navBarSiteLogoMobile-img"
              ></img>
            </NavLink>
          </div>

          <div>
            <ul
              className={` ${classes.navBarRow_primary} main-menu menu-right menuq1`}
            >
              {links}
            </ul>

            {isMenu && (
              <ul
                className={` ${classes.navBarRow_primary} ${boxClass.join(
                  " "
                )}`}
              >
                {" "}
                {/* // hambuggermenu and close button on small screen */}
                {mobileLinks()}
                {/* {user.loggedIn && (
              <div className='navBarSiteLogoMobile'>{linksMobileProfile}</div>
            )}
            <div className='navBarSiteLogoMobile languageSelectMobile'>
              {linksMobileLanguageSelect}
            </div> */}
              </ul>
            )}
          </div>

          <div className={"navBarSiteLogoMobile"}>{linksMobileLogin}</div>
          <div className="action">
            {isResponsiveclose === true ? (
              <>
                <span className="menubar__button " onClick={toggleClass}>
                  {" "}
                  <CgClose />
                </span>
              </>
            ) : (
              <>
                <span
                  className="menubar__button menubar__button1"
                  onClick={toggleClass}
                >
                  {" "}
                  <CgMenu />
                </span>
              </>
            )}
            {/* // search will only appear when all links are not opened in navabar */}
            {!isResponsiveclose && (
              <div className={"navBarSiteLogoMobile"}>{linksMobileSearch}</div>
            )}
          </div>
        </nav>
      </div>
      <div className="MobileNav">
        <MobileBottomNav />
      </div>
    </>
  );
};

export default NavBar;
