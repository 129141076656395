import * as classes from "./Footer.module.css";

import { FaDribbble } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import googlePlayBadge from "../../images/google-play-badge.png";
import appleStoreBadge from "../../images/Available_on_the_App_Store_(black).png";
import { Link } from "react-router-dom";
import * as settings from "../../configs/config_settings.json";

const BottomBar2 = (props) => {
  return (
    <div className={classes.FooterSection}>
      <div className={`${classes.Footer} font-200`}>
        <div className={classes.Footer_Box}>
          <div className={classes.Footer_bottom_link}>
            <ul>
              <li>
                <a href="https://www.mytvbroadcasting.my/"> ABOUT MYTV</a>
              </li>
              <li>
                <Link to="/tos">TERM OF USE</Link>
              </li>

              <li>
                <Link to={`/${settings.routes.privacyPolicy}`} exact>
                  PRIVACY POLICY
                </Link>
              </li>

              <li>
                <Link
                  to={`/${settings.routes.faq}`}
                  exact
                  style={{
                    textTransform: "unset ",
                  }}
                >
                  FAQs
                </Link>
              </li>
            </ul>
          </div>
          <div className={classes.SocialContents}>
            <div className={classes.Footer_social_link}>
              <ul>
                <li>
                  <a href="https://www.mytvbroadcasting.my/">
                    <FaDribbble color="#b2b2b2" />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/mytvbroadcasting/">
                    <FaFacebookF color="#b2b2b2" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/mytvbroadcasting_/">
                    <FaInstagram color="#b2b2b2" />
                  </a>
                </li>
              </ul>
            </div>
            <div className={classes.StoreContent}>
              <ul
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=my.mana2.mobile"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className={classes.PlayStore}
                      src={googlePlayBadge}
                      alt="google-play-badge"
                    />
                  </a>
                  {/* <a href='https://play.google.com/store/apps/details?id=my.mana2.mobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a> */}
                </li>
                <li>
                  <a
                    href="https://apps.apple.com/us/app/mytv-manamana/id1673030555"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className={classes.AppStore}
                      src={appleStoreBadge}
                      alt="appleStoreBadge"
                    />
                  </a>
                  {/* <a href='https://play.google.com/store/apps/details?id=my.mana2.mobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.navColorBar}>
        <div className={classes.navColorBar1}></div>
        <div className={classes.navColorBar2}></div>
      </div>
    </div>
  );
};

export default BottomBar2;
