import { useMyContext } from "../../../contexts/StateHolder";
import RenderItem from "../RenderItems/RenderItem";
import CategoryTitle from "../CategoryTitles/CategoryTitle";
import CategoryTitle2 from "../CategoryTitles/CategoryTitle2/CategoryTitle2";
import { useCallback, useEffect, useState } from "react";
import {
  getAssets,
  getSubCategories,
  getRootSubCategories,
  getSeries,
  getSeriesGroup,
} from "../../../scripts/dataHandlers";
import { createToken, createGroupItemId } from "../../../scripts/utils";
import Carousel from "react-multi-carousel";
import axios from "axios";
import useWindowDimensions from "../../WindowDimension";
import { useParams } from "react-router-dom";

// List of usable category components
const components = {
  CategoryTitle,
  CategoryTitle2,
};
/*
Fetch: getAssets with props.groupItemId
Renders: props categories with title and content in slick slider

*/
export default function RenderCategoriesWithTitles(props) {
  const windowDimension = useWindowDimensions();

  // Destructure props.settings
  const {
    id,
    mode,
    groupItemId,
    routes,
    slickSettings,
    assetProperty,
    categoryTitleComponent,
    itemImageComponent,
    itemTitleComponent,
    maintainImageAspectRatio,
    imageType = "coverImage",
    cutText,
  } = props.settings;
  const { takeGroupItemIdfromUrl } = props;

  const [allCategoryItems, setAllCategoryItems] = useState([]);

  // Bring stateholders from context
  const { allCategories, organizationId, key, language, user, setChosenItem } =
    useMyContext(); // + allCategoryItems, setAllCategoryItems when using context
  const { categoriesId } = useParams();
  // console.log(allCategories?.length > 0 && organizationId && key && language && slickSettings, takeGroupItemIdfromUrl && categoriesId);

  const removeMultipleSeriesEpisode = useCallback(async (assetsInCategory) => {
    // here if the asset belongs to series we take only one

    let isNotSerieAsset = assetsInCategory.filter(
      (el) => el.series?.length === 0
    );
    let isSerieAsset = assetsInCategory.filter((el) => el.series?.length > 0);
    // console.log("isSerieAsset", isSerieAsset);

    const uniquesSerieAsset = [
      ...isSerieAsset
        .reduce((map, item) => map.set(item?.series?.id, item), new Map())
        .values(),
    ];

    if (uniquesSerieAsset?.length) {
      await Promise.all(
        uniquesSerieAsset.map(async (el) => {
          let re11 = await getSeriesGroup(
            organizationId,
            el?.seriesId,
            language,
            0
          );
          // console.log("re11", re11);
          el.parentSeriesTitle = re11[0]?.title;
          el.parentSeriesDescription = re11[0]?.description;
        })
      );
    }

    // console.log("d", uniquesSerieAsset);
    assetsInCategory = [...isNotSerieAsset, ...uniquesSerieAsset];
    // console.log("assetsInCategory", assetsInCategory);
    return assetsInCategory;
  }, []);

  // UseEffect that will check mode and context data by id, if component has already fetched items once
  useEffect(() => {
    const getDataFromAPI = async () => {
      try {
        /*       TODO: Outdated guide (?)
                mode 1: show listed categories in config or from request
                mode 2: read orgId from request or from config file, and list all top level categories of that organisation
                mode 3: read root categoryId from request or config file, this will show hidden categorynames on assets
                mode 4: get subcategories and their assets, ignoring hidden categories from asset folders
                */

        let objectData = [];

        if (mode === "mode_1") {
          // Create token
          const token = createToken(
            organizationId,
            takeGroupItemIdfromUrl && categoriesId ? categoriesId : groupItemId,
            key
          );

          // Create list of categories
          const catList = await getSubCategories(
            organizationId,
            token,
            takeGroupItemIdfromUrl && categoriesId ? categoriesId : groupItemId,
            language,
            user
          );

          // Call getAssets datahandler to get list of all items from all categories
          const itemList = await getAssets(
            organizationId,
            takeGroupItemIdfromUrl && categoriesId ? categoriesId : groupItemId,
            token,
            language,
            assetProperty,
            user
          );

          // Go through categorylist and find assets that share same id than category
          catList.forEach(async (cat) => {
            let assetsInCategory = itemList?.filter((asset) =>
              asset.groupItemIds.includes(String(cat.id))
            );

            // assetsInCategory = await removeMultipleSeriesEpisode(assetsInCategory);
            // console.log("assetsInCategory1", assetsInCategory);
            let isNotSerieAsset = assetsInCategory?.filter(
              (el) => el.series?.length === 0
            );
            let isSerieAsset = assetsInCategory?.filter(
              (el) => el.series?.length > 0
            );
            // console.log("isSerieAsset", isSerieAsset);

            let uniquesSerieAsset = [
              ...isSerieAsset
                ?.reduce(
                  (map, item) => map.set(item?.series?.id, item),
                  new Map()
                )
                .values(),
            ];

            if (uniquesSerieAsset?.length) {
              await Promise.all(
                uniquesSerieAsset.map(async (el) => {
                  let re11 = await getSeriesGroup(
                    organizationId,
                    el?.seriesId,
                    language,
                    0
                  );
                  // console.log("re11", re11);
                  el.parentSeriesTitle = re11[0]?.title;
                  el.parentSeriesDescription = re11[0]?.description;
                })
              );
            }

            // console.log("d", uniquesSerieAsset);
            assetsInCategory = [...isNotSerieAsset, ...uniquesSerieAsset];
            // console.log("assetsInCategory", assetsInCategory);

            objectData.push({ category: cat, assets: assetsInCategory });
          });
        } else if (mode === "mode_2") {
          const idString = createGroupItemId(allCategories);

          // Create token
          const token = createToken(organizationId, idString, key);

          let response = await getAssets(
            organizationId,
            idString,
            token,
            language,
            assetProperty,
            user
          );

          // Go through categorylist and find assets that share same id than category
          allCategories.forEach(async (cat) => {
            // console.log("cat.id", cat.id);
            // console.log("response", response);
            let assetsInCategory = response.filter((asset) =>
              asset.groupItemIds.includes(String(cat.id))
            );
            // let assetsInCategory = response.filter((asset) => asset.folder.includes(String(cat.id)));

            // let isNotSerieAsset = assetsInCategory.filter((el) => el.series?.length === 0);
            // let isSerieAsset = assetsInCategory.filter((el) => el.series?.length > 0);
            // console.log("isSerieAsset", isSerieAsset);

            // const uniquesSerieAsset = [...isSerieAsset.reduce((map, item) => map.set(item?.series?.id, item), new Map()).values()];

            // if (uniquesSerieAsset?.length) {
            //     await Promise.all(
            //         uniquesSerieAsset.map(async (el) => {
            //             let re11 = await getSeriesGroup(organizationId, el?.seriesId, language, 0);
            //             console.log("re11", re11);
            //             el.parentSeriesTitle = re11[0]?.title;
            //             el.parentSeriesDescription = re11[0]?.description;
            //         })
            //     );
            // }

            // console.log("d", uniquesSerieAsset);
            // assetsInCategory = [...isNotSerieAsset, ...uniquesSerieAsset];
            // console.log("assetsInCategory", assetsInCategory);

            objectData.push({ category: cat, assets: assetsInCategory });
          });
        } else if (mode === "mode_3") {
          // Get root category id from config and fetch list of subcategories
          const subCatList = await getRootSubCategories(
            organizationId,
            key,
            language,
            groupItemId,
            user
          );

          // Create groupItemId from list of subcategories
          const idString = createGroupItemId(subCatList);

          // Create token
          const token = createToken(organizationId, idString, key);

          let response = await getAssets(
            organizationId,
            idString,
            token,
            language,
            assetProperty,
            user
          );

          // Go through categorylist and find assets that share same id than category
          subCatList.forEach(async (cat) => {
            let assetsInCategory = response.filter((asset) =>
              asset.groupItemIds.includes(String(cat.id))
            );

            assetsInCategory = await removeMultipleSeriesEpisode(
              assetsInCategory
            );

            objectData.push({ category: cat, assets: assetsInCategory });
          });
        } else if (mode === "mode_4") {
          // Create token
          const token = createToken(organizationId, groupItemId, key);

          // Create list of categories
          const catList = await getSubCategories(
            organizationId,
            token,
            groupItemId,
            language,
            user
          );

          await Promise.all(
            catList[0].groupItems.map(async (group) => {
              try {
                // Call createToken function to create new token from given data
                const token = createToken(organizationId, group.id, key);

                let assetsInCategory = await getAssets(
                  organizationId,
                  group.id,
                  token,
                  language,
                  assetProperty,
                  user
                );
                assetsInCategory = await removeMultipleSeriesEpisode(
                  assetsInCategory
                );

                objectData.push({ category: group, assets: assetsInCategory });
              } catch (error) {
                console.log("error" + error);
              }
            })
          );
        }
        setAllCategoryItems(objectData);
      } catch (err) {
        console.log(err);
      }
    };

    if (
      allCategories?.length > 0 &&
      organizationId &&
      key &&
      language &&
      slickSettings
    ) {
      //  && !allCategoryItems[id]
      // If there's no held data in context by component id, get data from api
      getDataFromAPI();
    } /* else if (allCategoryItems[id]?.length > 0) {
            // If there's data available by id in context, get data from context
            getDataFromContext();
        }*/

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCategories, slickSettings]);

  let className1 = "";

  const clickHandler = (item, i) => {
    setChosenItem(item);
  };
  // console.log("allCategoryItems", allCategoryItems);
  const renderCategories = () => {
    return allCategoryItems.map((group) => {
      // console.log("group", group);
      // Choose child component for Title
      const TitleComponent = components[categoryTitleComponent];

      let categoryPushRoute = `/${routes.categories}/${group.category.id}`;

      if (group?.assets?.length > 0 && slickSettings) {
        let arr = Object.keys(slickSettings.responsive).map(
          (k) => slickSettings.responsive[k]
        );
        let arr1 = [...arr];

        let j = [...arr1].find(
          (el) =>
            el.breakpoint.min <= windowDimension.width &&
            el.breakpoint.max >= windowDimension.width
        );

        if (group?.assets.length === j.items) {
          className1 = "addSeecondary";
        } else {
          className1 = "";
        }
      }

      return group.assets.length > 0 ? (
        <div
          className={`${className1} categoriesContainer`}
          key={`${id} ${group.category.id}`}
        >
          <TitleComponent
            id={group.category.id}
            title={group.category.title}
            routes={routes}
            item={group.category}
            showSerieViewAll={true}
            pushRoute={categoryPushRoute}
            extraClassname={props.extraClassname}
          />
          <Carousel {...slickSettings}>
            {group.assets.map((item, i) => {
              // console.log("item", item);
              let pushRoute = "";
              let localCatId = group.category.id * 1;
              if (localCatId === 215458108) {
                // if the category id is same as trendingMyTv we show some other cat from that assets
                localCatId = item?.folders?.find(
                  (el) => el.id !== localCatId
                )?.id;
              }
              localCatId = localCatId ? localCatId : 215458108;

              if (item.isSerie === true || item.series?.length > 0) {
                pushRoute = `/${routes.serieRoute}/${organizationId}/${item.id}`; //${item.series[0].id}${item?.series[1]?.id ? "/"+item.series[1].id : ""}`
              } else {
                pushRoute = `/${routes.videoRoute}/${organizationId}/${item.id}/${localCatId}`;
              }

              return (
                <div key={`${id} ${i}`} onClick={() => clickHandler(item, i)}>
                  <RenderItem
                    key={`${id} ${i}`}
                    item={item}
                    routes={routes}
                    itemImageComponent={itemImageComponent}
                    itemTitleComponent={itemTitleComponent}
                    pushRoute={pushRoute}
                    imageType={imageType}
                    showCategoryName={false}
                    showPlayIcon={true}
                    // whether to maintain aspect ratio 16/9
                    maintainImageAspectRatio={maintainImageAspectRatio}
                    showDuration={props.showDuration}
                    showReleaseYear={props.showReleaseYear}
                    showPublishedDate={props.showPublishedDate}
                    cutText={cutText}
                    hideDescription={props.hideDescription}
                    showTitle={true}
                    folderPage={true}
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
      ) : null;
    });
  };
  return allCategoryItems.length > 0 ? renderCategories() : null;
}
