import "./index.css";
import App from "./App";
import { hydrate, render } from "react-dom";

import { BrowserRouter as Router } from "react-router-dom"; // HashRouter, basename //app to router // BrowserRouter
import StateHolder from "./contexts/StateHolder";
import ContextFunctions from "./contexts/ContextFunctions";
import "./i18n/config";
import ScrollToTop from "./components/ScrollToTop";
import { CookiesProvider } from "react-cookie";
import { HelmetProvider } from "react-helmet-async";

import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { SkeletonTheme } from "react-loading-skeleton";
import React from "react";

const helmetContext = {};

const queryClient = new QueryClient();

console.log = function () {}; // Disable all console.logs from console

const app = (
  <SkeletonTheme baseColor="#202020" highlightColor="#444">
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <Router>
          <CookiesProvider>
            <HelmetProvider context={helmetContext}>
              <StateHolder>
                <ContextFunctions>
                  <ScrollToTop />
                  <App />
                </ContextFunctions>
              </StateHolder>
            </HelmetProvider>
          </CookiesProvider>
        </Router>
      </React.StrictMode>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  </SkeletonTheme>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  render(app, rootElement);
}
